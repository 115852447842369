<template>
  <div>
    <div
      v-if="loader"
      class="fixed inset-0 flex items-center justify-center bg-white z-50"
    >
      <img
        src="@/assets/imgs/tuin_loader.gif"
        alt="cargando..."
        class="w-[500px]"
      />
    </div>

    <!-- Mapa del sitio  -->
    <div class="flex flex-wrap">
      <div class="flex flex-1">
        <fwb-heading tag="h2"> Descubre la Bolsa Inmobiliaria </fwb-heading>
      </div>
    </div>
    <!-- Mapa del sitio  -->

    <div
      class="flex flex-col rounded-md w-full h-full bg-white shadow-xl mt-4 p-4"
    >
      <p>
        Accede a todas las propiedades disponibles con comisión compartida por
        renta o venta. Si te interesa alguna oportunidad, ingresa a el detalle
        de propiedad y haz clic en el botón de WhatsApp o llama para conectar
        directamente con el asesor responsable.
      </p>
    </div>

    <!-- Propiedades -->

    <div
      id="top"
      ref="topElement"
      class="flex flex-col rounded-md w-full h-full bg-white shadow-xl mt-4 p-4"
    >
      <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 p-2 mt-5">
        <div> 
          <fwb-select
            v-model="filterTypeOperation"
            :options="movements"
            label="OPERACIÓN"
            placeholder="Selecciona una opción"
            @change="change_filter"
          />
        </div>

        <div> 
          <fwb-select
            v-model="filterTypeProperty"
            :options="operations"
            label="TIPO DE PROPIEDAD"
            placeholder="Selecciona una opción"
            @change="change_filter"
          />
        </div>

        <div> 
          <fwb-select
            v-model="filterState"
            :options="states"
            label="Estado"
            placeholder="Selecciona una opción"
            @change="change_filter"
          />
        </div>
      </div>

      <div
        v-if="properties.length > 0"
        class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 p-2 mt-5"
      >
        <div v-for="(property, index) in properties" :key="index">
          <CardPropertyComponent :property="property" />
        </div>
      </div>

      <div
        v-if="properties.length > 0"
        class="flex w-full justify-center items-center mt-10 mb-10"
      >
        <fwb-pagination
          v-model="currentPage"
          :total-pages="totalPages"
          show-icons
          @click="changePage()"
        />
      </div>
    </div>
    <div
      v-if="properties.length == 0"
      class="flex flex-col justify-center items-center mt-10"
    >
      <h1>Aún no hay propiedades para compartir</h1>
    </div>
  </div>
</template>
<script setup>
import { FwbHeading, FwbPagination,FwbSelect } from "flowbite-vue";
import CardPropertyComponent from "./CardPropertyComponent.vue";
import { ref, computed, onMounted } from "vue";
import axios from "axios";

import { useStore } from "vuex";
const store = useStore();
let session = computed(() => store.getters.getUserSession);

let properties = ref([]);
let loader = ref(false);

let filterTypeOperation   = ref('');
let filterTypeProperty    = ref('');
let filterState           = ref('');

let operations  = [];
let movements   = [];
let states      = [];

let currentPage = ref(1);
let totalPages = ref(1);
const topElement = ref(null);

async function getList() {
  try {
    let options = {
      headers: {
        Authorization: `Bearer ${session.value.token_api}`,
      },
    };

    let link = `${process.env.VUE_APP_API_BASE_URL}/broker/exchange/${session.value.id}?page=${currentPage.value}`;
    
    if(filterTypeOperation.value != ''){
      link += `&type_operation=${filterTypeOperation.value}`
    }

    if(filterTypeProperty.value != ''){
      link += `&type_property=${filterTypeProperty.value}`
    }

    if(filterState.value != ''){
      link += `&state=${filterState.value}`
    }

    const response = await axios.get(link, options);

    if (response.status == 200) {
      properties.value = response.data.resp;
      let pages = response.data.pages;
      currentPage.value = pages.current_page;
      totalPages.value = pages.last_page;
      scrollToTop();

      loader.value = false;
    }
  } catch (error) {
    console.error("Error fetching data:", error);
  }
}

async function getTypeProperty(){
  try {
    let options = {
      headers: {
        'conttent-type': 'json'
      },
    };

    let link = `${process.env.VUE_APP_API_BASE_URL}/catalogue/type_property`;

    const response = await axios.get(link, options);

    if (response.status == 200) {
      let type_operation = response.data.resp;  
      
      operations = type_operation.map(type => ({
          value: type.id,
          name: type.name
      }));
    }
  } catch (error) {
    console.error("Error fetching data:", error);
  }
}

async function getTypeOperation(){
  try {
    let options = {
      headers: {
        'conttent-type': 'json'
      },
    };

    let link = `${process.env.VUE_APP_API_BASE_URL}/catalogue/type_movement_property`;

    const response = await axios.get(link, options);

    if (response.status == 200) {
      let type_properties = response.data.resp;      

      movements = type_properties.map(type => ({
          value: type.id,
          name: type.name
      }));
    }
  } catch (error) {
    console.error("Error fetching data:", error);
  }
}

async function getState(){
  try {
    let options = {
      headers: {
        'conttent-type': 'json'
      },
    };

    let link = `${process.env.VUE_APP_API_BASE_URL}/catalogue/states`;

    const response = await axios.get(link, options);

    if (response.status == 200) {
      let estados = response.data.resp;  
      
      states = estados.map(estado => ({
          value: estado.id,
          name: estado.estado
      }));

    }
  } catch (error) {
    console.error("Error fetching data:", error);
  }
}

const scrollToTop = () => {
  if (topElement.value) {
    topElement.value.scrollIntoView({ behavior: "smooth" });
  }
};

async function changePage(){
  console.log('PAGE',currentPage.value)  
  getList();
}

async function change_filter(){
  getList();
}

onMounted(async () => {
  loader.value = true;
  await getTypeProperty();
  await getTypeOperation();
  await getState();

  await getList();
  
  topElement.value = document.getElementById("top");
});
</script>