<template>
  <div class="p-0 m-0">
    <NavBarComponent
      v-show="isSessionActive"
      class="block sm:block md:hidden"
    />
    <div class="flex">
      <SideBarComponent
        v-show="isSessionActive"
        class="hidden md:block w-[15%] bg-black"
      />
      <main
        :class="[
          mainClass,
          'flex-1 w-full sm:w-full ml-0 h-mx-auto p-4',
          isSessionActive ? 'sm:w-[85%] sm:ml-[15%]' : '',
        ]"
      >
        <!-- Contenedor principal para la información -->
        <router-view />
      </main>
    </div>
  </div>
</template>

<script>
import { onMounted, computed } from "vue";
import { useStore } from "vuex";
import NavBarComponent from "./components/NavBarComponent.vue";
import SideBarComponent from "./components/SideBarComponent.vue";

export default {
  components: {
    NavBarComponent,
    SideBarComponent,
  },
  setup() {
    const store = useStore();

    // Cargar la sesión cuando la aplicación se inicia
    onMounted(() => {
      store.dispatch("loadUserSession");
    });

    // Computed para verificar si la sesión está activa
    const isSessionActive = computed(() => store.getters.getUserSession);

    // Cambiar la clase de main según la sesión
    const mainClass = computed(() => {
      return isSessionActive.value ? "bg-gray-25 " : "bg-black ";
    });

    return {
      isSessionActive,
      mainClass,
    };
  },
};
</script>

<style scoped>
</style>


