<template>
  <div>
    <div>
      <LoaderTuin :is-loading="loader" />
    </div>
    <ToastAlert
      :is-active="toastAlert"
      :type="typeMsg"
      :message="msg"
    />
    <!-- Dropzone para múltiples archivos -->
    <div
      :class="[
        'border-4 border-dashed p-6 rounded-md cursor-pointer',
        dragging ? 'border-blue-500 bg-blue-100' : 'border-gray-300 bg-gray-50',
      ]"
      class="flex flex-col items-center justify-center space-y-4 p-4 m-4"
      @dragover.prevent
      @dragenter.prevent="dragging = true"
      @dragleave="dragging = false"
      @drop.prevent="handleDrop"
      @click="triggerFileInput"
    >
      <p class="text-gray-500 text-center">
        Arrastra y suelta archivos aquí o haz clic para seleccionarlos
      </p>
      <input
        ref="fileInput"
        type="file"
        class="hidden"
        accept="image/*"
        multiple
        @change="handleFileSelect"
      >
    </div>

    <!-- Lista de previews de archivos cargados -->
    <div
      v-if="files.length > 0"
      class="mt-4"
    >
      <h3 class="text-lg font-semibold">
        Archivos cargados:
      </h3>
      <p>
        Estos son los archivos que has cargado desde tu dispositivo. Si todo
        está correcto, haz clic en el botón 'Guardar imágenes'. Una vez
        guardadas, podrás verlas en la ficha técnica.
      </p>
      <p>
        <strong>
          Recuerda colocar la mejor vista de tu inmueble en la posición número
          uno, ya que será la foto principal al compartir en Facebook y
          WhatsApp. Si ya tienes imágenes guardadas, asegúrate de no duplicar el
          orden con las existentes
        </strong>
      </p>

      <div class="grid grid-cols-2 sm:grid-cols-4 gap-4 mt-4">
        <div
          v-for="(file, index) in files"
          :key="index"
          class="max-w-sm mx-auto bg-white shadow-md rounded-md overflow-hidden"
        >
          <img
            :src="file.base64"
            alt="Imagen"
            class="w-full h-48 object-cover"
          >
          <div class="p-4 text-center">
            <p class="text-gray-700 text-sm mb-4">
              {{ file.name }}
            </p>
            <div class="flex justify-center items-center space-x-4">
              <fwb-input
                v-model="file.order"
                label="Orden"
                type="number"
                min="1"
                class="w-20"
              />
              <button
                class="text-gray-500 hover:text-red-500 items-center justify-items-center"
              >
                <svg
                  class="mt-4"
                  xmlns="http://www.w3.org/2000/svg"
                  width="25"
                  height="25"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="none"
                    stroke="#df0707"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1.5"
                    d="m18 9l-.84 8.398c-.127 1.273-.19 1.909-.48 2.39a2.5 2.5 0 0 1-1.075.973C15.098 21 14.46 21 13.18 21h-2.36c-1.279 0-1.918 0-2.425-.24a2.5 2.5 0 0 1-1.076-.973c-.288-.48-.352-1.116-.48-2.389L6 9m7.5 6.5v-5m-3 5v-5m-6-4h4.615m0 0l.386-2.672c.112-.486.516-.828.98-.828h3.038c.464 0 .867.342.98.828l.386 2.672m-5.77 0h5.77m0 0H19.5"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>

      <button
        class="bg-blue-500 text-white px-4 py-2 rounded mt-4"
        @click="saveFiles"
      >
        Guardar imágenes
      </button>
    </div>

    <!-- Lista de imagenes  -->
    <div
      v-if="gallery.length > 0"
      class="mt-10"
    >
      <h3 class="text-lg font-semibold">
        Fotos de la propiedad
      </h3>
      <p>
        Aquí puedes ver las fotos que se muestran en la ficha de tu propiedad.
        Debajo de cada imagen está el campo 'Orden', que define su posición.
      </p>
      <p>
        <strong>
          Coloca la mejor vista de tu inmueble en la posición número uno, ya que
          será la foto principal al compartir en Facebook y WhatsApp.
        </strong>
      </p>

      <div class="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-4 mt-4">
        <div
          v-for="(img, index) in gallery"
          :key="index"
          class="max-w-sm mx-auto bg-white shadow-md rounded-md overflow-hidden"
        >
          <img
            :src="img.path_img"
            alt="Imagen"
            class="w-full h-48 object-cover"
          >
          <div class="p-4 text-center">
            <div class="flex justify-center items-center space-x-4">
              <fwb-input
                v-model="img.order"
                label="Orden"
                type="number"
                min="1"
                class="w-20"
                @change="updateOrder(index)"
              />
              <button
                class="text-gray-500 hover:text-red-500 items-center justify-items-center"
              >
                <svg
                  class="mt-4"
                  xmlns="http://www.w3.org/2000/svg"
                  width="25"
                  height="25"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="none"
                    stroke="#df0707"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1.5"
                    d="m18 9l-.84 8.398c-.127 1.273-.19 1.909-.48 2.39a2.5 2.5 0 0 1-1.075.973C15.098 21 14.46 21 13.18 21h-2.36c-1.279 0-1.918 0-2.425-.24a2.5 2.5 0 0 1-1.076-.973c-.288-.48-.352-1.116-.48-2.389L6 9m7.5 6.5v-5m-3 5v-5m-6-4h4.615m0 0l.386-2.672c.112-.486.516-.828.98-.828h3.038c.464 0 .867.342.98.828l.386 2.672m-5.77 0h5.77m0 0H19.5"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Fin lista de imagenes -->
  </div>
</template>
  
<script setup>
import { ref, onMounted, computed, defineProps } from "vue";
import { FwbInput } from "flowbite-vue";
import axios from "axios";

import LoaderTuin from "@/commond/LoaderTuin.vue";
import ToastAlert from "@/commond/ToastAlert.vue";
import { useStore } from "vuex";
const store = useStore();
let session = computed(() => store.getters.getUserSession);

const fileInput = ref(null);

const dragging = ref(false);
const files = ref([]); // Lista de archivos en base64
const gallery = ref([]);

let toastAlert = ref(false);
let typeMsg = ref("");
let msg = ref("");
let loader = ref(false);

const props = defineProps({
  resp: {
    type: Object,
    required: true,
    default: () => ({ property_name: "" }),
  },
  propertyId: {
    type: Number,
    required: true,
    default: 0,
  },
});

const handleDrop = (event) => {
  dragging.value = false;
  const droppedFiles = event.dataTransfer.files;
  handleFiles(droppedFiles);
};

const handleFileSelect = (event) => {
  const selectedFiles = event.target.files;
  handleFiles(selectedFiles);
};

const handleFiles = (fileList) => {
  for (let i = 0; i < fileList.length; i++) {
    const file = fileList[i];
    convertToBase64(file);
  }
};

const triggerFileInput = () => {
  fileInput.value.click();
};

const convertToBase64 = (file) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => {
    files.value.push({
      name: file.name,
      size: file.size,
      base64: reader.result,
    });
  };
  reader.onerror = (error) => {
    console.error("Error al convertir a base64: ", error);
  };
};

// Función para enviar los archivos cuando se hace clic en guardar
async function saveFiles() {
  // Aquí puedes enviar el array `files.value` al backend con axios
  let url = `${process.env.VUE_APP_API_BASE_URL}/broker/property/${props.propertyId}/upload_images`;

  let options = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${session.value.token_api}`,
    },
  };

  try {
    const response = await axios.post(url, files.value, options);

    if (response.status == 401) {
      console.log("Hay que sacarlo de la sesion ");
    }

    if (response.status == 200) {
      console.log(response);
      showToast("success", response.data.message);
      loader.value = true;
      await getGallery();
      files.value = [];
    }
  } catch (error) {
    console.error("Error fetching data:", error);
  }
}

async function showToast(type, message) {
  toastAlert.value = true;
  typeMsg.value = type;
  msg.value = message;

  setTimeout(() => {
    closeToast();
  }, 3000);
}

async function closeToast() {
  toastAlert.value = false;
  typeMsg.value = "";
  msg.value = "";
}

onMounted(async () => {
  console.log("entro a esta baina propiedad", props.propertyId);
  loader.value = true;
  await getGallery();
});

async function getGallery() {
  let url = `${process.env.VUE_APP_API_BASE_URL}/broker/property/${props.propertyId}/gallery`;

  let options = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${session.value.token_api}`,
    },
  };

  try {
    const response = await axios.get(url, options);

    if (response.status == 401) {
      console.log("Hay que sacarlo de la sesion ");
    }

    if (response.status == 200) {
      showToast("success", response.data.message);
      gallery.value = response.data.resp;
    }
    loader.value = false;
  } catch (error) {
    console.error("Error fetching data:", error);
  }
}

async function updateOrder(pos) {
  let field_order = gallery.value[pos].order;
  let field_id = gallery.value[pos].id;

  if (field_order != null || field_order != "" || field_order.length != 0) {
    //loader.value = true;
    let url = `${process.env.VUE_APP_API_BASE_URL}/broker/property/${props.propertyId}/update_order_img`;

    let options = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${session.value.token_api}`,
      },
    };

    let param_post = {
      order: field_order,
      img_id: field_id,
    };

    try {
      const response = await axios.put(url, param_post, options);

      if (response.status == 401) {
        console.log("Hay que sacarlo de la sesion ");
      }

      if (response.status == 200) {
        console.log(response);
        //await getGallery();
      }
      loader.value = false;
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }
}
</script>
    
<style scoped>
/* Puedes agregar estilos personalizados si lo deseas */
</style>
  