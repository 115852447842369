<!-- eslint-disable vue/no-v-html -->
<template>
  <div>
    <div>
      <LoaderTuin :is-loading="loader" />
    </div>
    <ToastAlert
      :is-active="toastAlert"
      :type="typeMsg"
      :message="msg"
    />

    <div class="p-6">
      <!-- Contenedor de la cuadrícula -->
      <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
        <!-- Iterar sobre los registros -->
        <div
          v-for="(item, index) in list"
          :key="item.id"
          class="border rounded-lg p-4 flex flex-col items-center space-y-4 bg-white shadow hover:shadow-lg transition"
        >
          <!-- Mostrar el ícono como SVG -->
          <div
            class=" w-16 h-16 flex justify-center items-center"
            v-html="item.img_icon"
          />
          <!-- Nombre -->
          <h3 class="text-lg font-semibold text-gray-700 text-center">
            {{ item.name }} 
          </h3>
          <!-- Tipo -->          
          <!-- Estado activo -->
          <div
            class="px-3 py-1 rounded-full text-xs font-medium"
          >
            <fwb-toggle 
              v-model="item.is_active"
              :label="item.is_active ? 'Activo' : 'Inactivo' "
              @change="changeStatusOption( index )" 
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
  import { ref, onMounted, computed, defineProps } from "vue";
  import axios from "axios";
  import { FwbToggle } from 'flowbite-vue'
  import LoaderTuin  from "@/commond/LoaderTuin.vue";


  import { useStore } from "vuex";
  const store = useStore();
  let session = computed(() => store.getters.getUserSession);

  const props = defineProps({
    resp: {
      type: Object,
      required: true,
      default: () => ({ property_name: "" }),
    },
    propertyId: {
      type: Number,
      required: true,
      default: 0,
    },
    section: {
      type: String,
      required: true,
      default: '',
    }
  });

  let toastAlert = ref(false);
  let typeMsg = ref("");
  let msg = ref("");
  let loader = ref(false);
  let list = ref([]);

  async function getCatalog() {
    let url = `${process.env.VUE_APP_API_BASE_URL}/broker/property/${props.propertyId}/${props.section}`;

    let options = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${session.value.token_api}`,
      },
    };

    try {
      const response = await axios.get(url, options);

      if (response.status == 401) {
        console.log("Hay que sacarlo de la sesion ");
      }

      if (response.status == 200) {
        list.value = response.data.resp;        
      }
      loader.value = false;
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  onMounted(async () => {
    loader.value = true;
    await getCatalog();
  });

  async function changeStatusOption(pos){
    
    let url = `${process.env.VUE_APP_API_BASE_URL}/broker/property/${props.propertyId}/update_option`;

    let options = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${session.value.token_api}`,
      },
    };

    let param_post = {
      "section": props.section,
      "property_id": props.propertyId,
      "id": list.value[pos].id,
      "is_active": list.value[pos].is_active
    }
    
    try {
      const response = await axios.put(url, param_post , options);

      if (response.status == 401) {
        console.log("Hay que sacarlo de la sesion ");
      }

      if (response.status == 200) {
        console.log('[RESPUESTA]', response.data.message )
      }
      loader.value = false;
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }
</script>
<style>
.inline-list {
  display: flex;
  align-items: center;
  list-style: none;
  padding: 0;
  margin: 0;
}
.icon-svg{
    color: #fff !important;
}
</style>