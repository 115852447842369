<template>
  <div class="flex flex-col items-center justify-center min-h-screen p-6">
    <ToastAlert
      :is-active="toastAlert"
      :type="typeMsg"
      :message="msg"
    />

    <div
      v-if="loader"
      class="flex flex-col rounded-md w-full h-full shadow-xl mt-4 p-4 justify-center items-center"
    >
      <img
        src="@/assets/imgs/tuin_loader.gif"
        alt="cargando..."
        class="w-[500px]"
      >
    </div>

    <div
      v-if="!loader"
      class="border-2 border-tuin_base-0 rounded-lg p-8 max-w-md w-full"
    >
      <div v-if="isValidToken">
        <h1 class="text-2xl font-bold text-white mb-4 text-center">
          ¡Gracias por confirmar tu correo!
        </h1>
        <p class="text-white text-center mb-6">
          Tu cuenta ha sido activada exitosamente. Ahora puedes comenzar a
          utilizar nuestros servicios.
        </p>
        <div class="flex justify-center mb-6">
          <img
            src="@/assets/imgs/LogoTuin.svg"
            alt=""
            class="w-40 h-40"
          >
        </div>
        <p class="text-white text-center mb-4">
          Puedes proceder a iniciar sesión en nuestra plataforma para comenzar a
          explorar todas las funcionalidades disponibles.
        </p>

        <div class="flex justify-center">
          <button
            class="bg-tuin_base-0 text-white py-2 px-4 rounded-lg hover:bg-white hover:text-tuin_base-0 transition-colors"
            @click="goToLogin"
          >
            Ir a iniciar sesión
          </button>
        </div>
      </div>

      <div v-if="!isValidToken">
        <div class="flex justify-center mb-6">
          <img
            src="@/assets/imgs/LogoTuin.svg"
            alt=""
            class="w-40 h-40"
          >
        </div>
        <h1 class="text-2xl font-bold text-white mb-4 text-center">
          Lo sentimos este correo de verificación ha expirado
        </h1>
        <div class="flex justify-center w-full mb-5 mt-4">
          <p class="text-white text-xl ">
            ¿Quieres volver a intentar la verificación?
          </p>
        </div>
        <div class="flex justify-center w-full mb-5 mt-4">
          <fwb-input
            v-model="email"            
            type="email"
            placeholder="Ingresa tu correo"
            required=""
            label-class="text-white"
            class="custom-label w-full"
          />
        </div>
        <div class="flex justify-center">
          <button
            class="bg-tuin_base-0 text-white py-2 px-4 rounded-lg hover:bg-white hover:text-tuin_base-0 transition-colors"
            :disabled="!email"
            @click="resendActivationLink"
          >
            Enviar correo de verificación
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script setup>
import { onMounted, ref } from "vue";
import axios from "axios";
import { FwbInput } from "flowbite-vue";
import ToastAlert from "@/commond/ToastAlert.vue";

const route = useRoute();
import { useRoute } from "vue-router";

let loader = ref(false);
const tokenTemp = route.params.token_temp;
let isValidToken = false;
let email = ref("");

let toastAlert = ref(false);
let typeMsg = ref("");
let msg = ref("");

function goToLogin() {
  // Lógica para redirigir al usuario a la página de inicio de sesión
  window.location.href = "/login";
}

async function validateToken() {
  try {
    let options = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    let post_params = {
      token: tokenTemp,
    };

    let link = `${process.env.VUE_APP_API_BASE_URL}/verify-account`;

    const response = await axios.post(link, post_params, options);

    if (response.status == 200) {
      console.log(response.data.status);
      isValidToken = true;
      loader.value = false;
    }
  } catch (error) {
    if (error.response.status == 422) {
      loader.value = false;
      console.error("Error fetching data:", error.response.data.status);
      isValidToken = error.response.data.status;
    }
  }
}

async function resendActivationLink() {  
  if (validateEmail(email.value)) {
    loader.value = true;
    try {
      let options = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      let post_params = {
        email: email,
        type_account: 3,
      };

      let link = `${process.env.VUE_APP_API_BASE_URL}/again-verify-account`;

      const response = await axios.post(link, post_params, options);
      console.log(response.status)
      if (response.status == 200) {
        showToast("success", response.data.message);
        loader.value = false;
        setTimeout(()=> {
            goToLogin()
        }, 2000 )
      }
    } catch (error) {
        
      if (error.response && error.response.status == 422) {
        loader.value = false;
        console.error("Error fetching data:", error.response.data.status);
        showToast("success", error.response.data.message);
      }
    }
  } else {
    showToast("danger", "Debes ingresar un email válido");
  }
}

async function closeToast() {
  toastAlert.value = false;
  typeMsg.value = "";
  msg.value = "";
}

async function showToast(type, message) {
  toastAlert.value = true;
  typeMsg.value = type;
  msg.value = message;

  setTimeout(() => {
    closeToast();
  }, 3000);
}

function validateEmail(email) {
  const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return re.test(email);
}

onMounted(async () => {
  loader.value = true;
  await validateToken();
});
</script>
  
  <style scoped>
/* Puedes personalizar estilos adicionales aquí */
/* Si el label tiene clases predeterminadas de Flowbite/Tailwind, puedes sobrescribirlas */
::v-deep .custom-label label {
  @apply text-white;
}

/* También puedes apuntar directamente al selector completo */
::v-deep .custom-label label.block.mb-2.text-sm.font-medium {
  @apply text-white;
}
</style>