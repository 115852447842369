<template>
  <div class="h-screen ">
    <ToastAlert
      :is-active="toastAlert"
      :type="typeMsg"
      :message="msg"
    />

    <div
      v-if="loader_page"
      class="fixed inset-0 flex items-center justify-center bg-black z-50"
    >
      <img
        src="@/assets/imgs/tuin_loader.gif"
        alt="cargando..."
        class="w-[500px]"
      >
    </div>

    <div v-if="!loader_page">
      <!-- Mapa del sitio  -->
      <div class="flex flex-wrap">
        <div class="flex flex-1">
          <fwb-heading tag="h2">
            Cambio de constraseña
          </fwb-heading>
        </div>
        <div class="flex flex-1 justify-end items-end">
          <fwb-breadcrumb>
            <fwb-breadcrumb-item href="/change-password">
              Cambio de constraseña
            </fwb-breadcrumb-item>
          </fwb-breadcrumb>
        </div>
      </div>
      <!-- Mapa del sitio  -->

      <div
        v-if="!loader_page"
        class="w-full rounded overflow-hidden shadow-lg bg-white mt-4 p-2"
      >
        <div class="flex flex-wrap">
          <div class="p-2 flex flex-col-2 mt-5">
            <p>
              Ingresa tu contraseña actual. Luego, escribe una nueva contraseña
              (mínimo 8 caracteres) y confírmala para completar el cambio.
            </p>
          </div>

          <div class="w-full sm:w-1/2 mb-4 sm:mb-0">
            <!-- Form -->

            <div class="p-2 flex flex-col-2">
              <div class="flex flex-1 justify-center items-center mt-2 p-2">
                <fwb-input
                  v-model="old_password"
                  type="password"
                  label="Contraseña actual"
                  placeholder="Contraseña actual"
                  size="sm"
                  class="w-full"
                />
              </div>
            </div>

            <div class="p-2 flex flex-col-2">
              <div class="flex flex-1 justify-center items-center mt-2 p-2">
                <fwb-input
                  v-model="password"
                  type="password"
                  label="Nueva contraseña"
                  placeholder="Nueva contraseña"
                  size="sm"
                  class="w-full"
                />
              </div>
            </div>

            <div class="p-2 flex flex-col-2">
              <div class="flex flex-1 justify-center items-center mt-2 p-2">
                <fwb-input
                  v-model="c_password"
                  type="password"
                  label="Confirmar nueva contraseña"
                  placeholder="Confirma tu nueva contraseña "
                  size="sm"
                  class="w-full"
                />
              </div>
            </div>

            <div class="p-2 flex flex-col">
              <div class="flex flex-1 justify-end items-end mt-5 p-2">
                <fwb-button
                  class="bg-tuin-100 hover:bg-tuin-100"
                  size="xl"
                  @click="save()"
                >
                  Guardar
                </fwb-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import {
  FwbBreadcrumb,
  FwbBreadcrumbItem,
  FwbHeading,
  FwbButton,
  FwbInput,
} from "flowbite-vue";
import { ref, computed } from "vue";
import ToastAlert from "@/commond/ToastAlert.vue";
import axios from "axios";

import { useStore } from "vuex";
const store = useStore();
let session = computed(() => store.getters.getUserSession);

let loader_page = ref(false);

let old_password = ref("");
let password = ref("");
let c_password = ref("");

let toastAlert = ref(false);
let typeMsg = ref("");
let msg = ref("");

async function save() {
  if (
    old_password.value.length == 0 ||
    password.value.length == 0 ||
    c_password.value.length == 0
  ) {
    showToast("danger", "Debes ingresar todos los campos");
    return;
  }

  if (password.value.length < 8) {
    showToast("danger", "La contraseña debe tener al menos 8 caracteres");
    return;
  }

  if (password.value != c_password.value) {
    showToast("danger", "La contraseña y confirmar contraseña no coinciden ");
    return;
  }

  let post_form = {
    password: old_password.value,
    new_password: password.value,
    broker_id: session.value.id,
  };

  let options = {
    headers: {
      Authorization: `Bearer ${session.value.token_api}`,
    },
  };

  let url_link = `${process.env.VUE_APP_API_BASE_URL}/broker/profile/change-password`;
  try {
    const response = await axios.put(url_link, post_form, options);

    if (response.status == 401) {
      store.dispatch("clearUserSession");
    }

    if (response.status == 200) {
      loader_page.value = false;
      showToast("success", "Datos guardados correctamente");
      old_password.value = "";
      password.value = "";
      c_password.value = "";
    }
  } catch (error) {
    if (error.response && error.response.status == 422) {
      console.log(error.response);
      showToast("danger", error.response.data.message);
    }
  }
}

async function showToast(type, message) {
  toastAlert.value = true;
  typeMsg.value = type;
  msg.value = message;

  setTimeout(() => {
    closeToast();
  }, 3000);
}

async function closeToast() {
  toastAlert.value = false;
  typeMsg.value = "";
  msg.value = "";
}
</script>