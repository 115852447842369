<template>
  <div>
    <div class="flex flex-wrap">
      <div class="w-1/3 p-4">
        <fwb-card href="#">
          <div class="flex flex-wrap">
            <div class="flex w-1/2 p-4 justify-center items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="2.3em"
                height="2.3em"
                viewBox="0 0 1920 1792"
                class="mb-4 text-tuin-100"
              >
                <path
                  fill="currentColor"
                  d="M593 896q-162 5-265 128H194q-82 0-138-40.5T0 865q0-353 124-353q6 0 43.5 21t97.5 42.5T384 597q67 0 133-23q-5 37-5 66q0 139 81 256m1071 637q0 120-73 189.5t-194 69.5H523q-121 0-194-69.5T256 1533q0-53 3.5-103.5t14-109T300 1212t43-97.5t62-81t85.5-53.5T602 960q10 0 43 21.5t73 48t107 48t135 21.5t135-21.5t107-48t73-48t43-21.5q61 0 111.5 20t85.5 53.5t62 81t43 97.5t26.5 108.5t14 109t3.5 103.5M640 256q0 106-75 181t-181 75t-181-75t-75-181t75-181T384 0t181 75t75 181m704 384q0 159-112.5 271.5T960 1024T688.5 911.5T576 640t112.5-271.5T960 256t271.5 112.5T1344 640m576 225q0 78-56 118.5t-138 40.5h-134q-103-123-265-128q81-117 81-256q0-29-5-66q66 23 133 23q59 0 119-21.5t97.5-42.5t43.5-21q124 0 124 353m-128-609q0 106-75 181t-181 75t-181-75t-75-181t75-181t181-75t181 75t75 181"
                />
              </svg>
            </div>
            <div class="flex w-1/2 p-4">
              <div class="flex flex-col">
                <div class="flex flex-1">
                  Prospectos
                </div>
                <div class="flex flex-1">
                  <span class="text-2xl"> 10 </span>
                </div>
              </div>
            </div>
          </div>
        </fwb-card>
      </div>

      <div class="w-1/3 p-4">
        <fwb-card href="#">
          <div class="flex flex-wrap">
            <div class="flex w-1/2 p-4 justify-center items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="3em"
                height="3em"
                viewBox="0 0 640 512"
                class="text-blue-500"
              >
                <path
                  fill="currentColor"
                  d="M288 350.1v1.9h-32c-17.7 0-32 14.3-32 32v88c0 22.1-17.9 40-40 40h-55.9c-1.5 0-3-.1-4.5-.2c-1.2.1-2.4.2-3.6.2h-16c-22.1 0-40-17.9-40-40V360c0-.9 0-1.9.1-2.8v-69.7h-32c-18 0-32-14-32-32.1c0-9 3-17 10-24L266.4 8c7-7 15-8 22-8s15 2 21 7l137.9 121.1c-12.3-1-25 3-34.8 11.7c-35.4 31.6-65.6 67.7-87.3 102.8c-20.9 33.9-37.2 72.3-37.2 107.5M480 512c-88.4 0-160-71.6-160-160c0-76.7 62.5-144.7 107.2-179.4c5-3.9 10.9-5.8 16.8-5.8c7.9-.1 16 3.1 22 9.2l46 46l11.3-11.3c11.7-11.7 30.6-12.7 42.3-1C624.5 268 640 320.2 640 352c0 88.4-71.6 160-160 160m64-111.8c0-36.5-37-73-54.8-88.4c-5.4-4.7-13.1-4.7-18.5 0c-17.7 15.3-54.7 51.8-54.7 88.4c0 35.3 28.7 64 64 64s64-28.7 64-64"
                />
              </svg>
            </div>
            <div class="flex w-1/2 p-4">
              <div class="flex flex-col">
                <div class="flex flex-1">
                  Propiedades
                </div>
                <div class="flex flex-1">
                  <span class="text-2xl"> 10 </span>
                </div>
              </div>
            </div>
          </div>
        </fwb-card>
      </div>

      <div class="w-1/3 p-4">
        <fwb-card href="#">
          <div class="flex flex-wrap">
            <div class="flex w-1/2 p-4 justify-center items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="3em"
                height="3em"
                viewBox="0 0 48 48"
                class="text-yellow-600"
              >
                <path
                  fill="currentColor"
                  d="M14.79 26.746L8 35.347V40h34v2H7a1 1 0 0 1-1-1V7h2v25.12l5.33-6.751A3 3 0 1 1 19 23.946l6.633 2.21a2.995 2.995 0 0 1 3.41-.97l6.378-7.653a3 3 0 1 1 1.536 1.28l-6.378 7.654A3 3 0 1 1 25 28.054l-6.633-2.21a2.995 2.995 0 0 1-3.577.902"
                />
              </svg>
            </div>
            <div class="flex w-1/2 p-4">
              <div class="flex flex-col">
                <div class="flex flex-1">
                  Visitas
                </div>
                <div class="flex flex-1">
                  <span class="text-2xl"> 10 </span>
                </div>
              </div>
            </div>
          </div>
        </fwb-card>
      </div>
      <!-- Agrega más cards según sea necesario -->
    </div>

    <div class="mt-10">
      <apexchart
        width="500"
        type="bar"
        :options="options"
        :series="series"
      />
    </div>
  </div>
</template>
  
<script setup>
import { ref } from "vue";
import { FwbCard } from "flowbite-vue";

const options = ref({
  chart: {
    
    id: "vuechart-example",
  },
  xaxis: {
    categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998],
  },
});

const series = ref([
  {
    name: "series-1",
    data: [30, 40, 45, 50, 49, 60, 70, 91],
  },
]);
</script>