<template>
  <div
    class="container mt-10"
    :class="{
      'h-screen': properties.length < 5,
      'mx-auto': properties.length > 5,
    }"
  >
    <LoaderTuin :is-loading="loader" />
    <div v-if="properties.length > 0" class="flex justify-end items-end">
      <router-link
        :to="{ name: 'propertyForm' }"
        class="flex items-center space-x-2 text-white bg-tuin-100 hover:bg-gray-700 p-2 rounded mt-10 mb-10"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="1.5em"
          height="1.5em"
          viewBox="0 0 16 16"
        >
          <g fill="white">
            <path
              d="M8.707 1.5a1 1 0 0 0-1.414 0L.646 8.146a.5.5 0 0 0 .708.708L2 8.207V13.5A1.5 1.5 0 0 0 3.5 15h4a.5.5 0 1 0 0-1h-4a.5.5 0 0 1-.5-.5V7.207l5-5l6.646 6.647a.5.5 0 0 0 .708-.708L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293z"
            />
            <path
              d="M16 12.5a3.5 3.5 0 1 1-7 0a3.5 3.5 0 0 1 7 0m-3.5-2a.5.5 0 0 0-.5.5v1h-1a.5.5 0 0 0 0 1h1v1a.5.5 0 1 0 1 0v-1h1a.5.5 0 1 0 0-1h-1v-1a.5.5 0 0 0-.5-.5"
            />
          </g>
        </svg>
        <span class="ml-2"> Agregar nueva propiedad </span>
      </router-link>
    </div>

    <div class="flex flex-wrap">
      <!-- filtro  -->
      <div v-if="properties.length > 0" class="flex flex-1">
        <fwb-input
          v-model="searchQuery"
          placeholder="Ingresa nombre de propiedad"
          class="w-full"
          @input="filterProperties"
        >
          <template #prefix>
            <div>
              <svg
                aria-hidden="true"
                class="w-5 h-5 text-gray-500 dark:text-gray-400"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                />
              </svg>
            </div>
          </template>
        </fwb-input>
      </div>
      <!-- filtro  -->

      <!-- <div class="flex flex-1 pl-3 hidden">  
            <fwb-button class=" bg-tuin-100 hover:bg-tuin-100">Buscar</fwb-button>
        </div> -->
    </div>

    <div
      v-if="properties.length > 0"
      id="top" ref="topElement"
      class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 p-2"
    >
      <fwb-card
        v-for="(item, index) in properties"
        :key="index"
        img-alt="Desk"
        variant="image"
        class="border-l-2"
      >
        <div class="w-full h-52 overflow-hidden flex justify-end">
          <div>
            <LabelTag
              :label="item.type_movement_name"
              :type_movement_id="item.type_movement_id"
            />
            
            <div
              v-if="item.total_image > 0"
              
              class="w-full h-[208px] bg-cover bg-center"
              :alt="item.property_name"
            >
            
            <img
                :src="item.image"
                :alt="item.property_name"
                class="w-full object-cover h-150"
              />
            </div>

            <div v-if="item.total_image == 0">
              <img
                src="@/assets/imgs/preview_house.svg"
                :alt="item.property_name"
                class="w-full object-cover h-150"
              />
            </div>
          </div>
        </div>
        <div class="p-5">
          <h5
            class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white"
          >
            {{ item.property_name }}
          </h5>

          <p class="mb-2 text-sm tracking-tight text-gray-900 dark:text-white">
            {{ item.property_address != ", , " ? item.property_address : "" }}
          </p>

          <div
            v-show="item.type_movement_id == 1"
            class="text-tuin-100 text-2xl font-bold"
          >
            {{ item.property_value }}
            <span class="text-sm">{{ item.currency }} </span>
          </div>
          <div
            v-show="item.type_movement_id == 2"
            class="text-tuin-100 text-2xl font-bold"
          >
            {{ item.rent_value }}
            <span class="text-sm">{{ item.currency }} </span>
          </div>

          <div
            class="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-4 gap-4 mt-4"
          >
            <div class="text-sm cursor-pointer">
              <fwb-tooltip>
                <template #trigger>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20px"
                    height="20px"
                    viewBox="0 0 640 512"
                    class="float-left text-blue-400"
                  >
                    <path
                      fill="currentColor"
                      d="M144 0a80 80 0 1 1 0 160a80 80 0 1 1 0-160m368 0a80 80 0 1 1 0 160a80 80 0 1 1 0-160M0 298.7C0 239.8 47.8 192 106.7 192h42.7c15.9 0 31 3.5 44.6 9.7c-1.3 7.2-1.9 14.7-1.9 22.3c0 38.2 16.8 72.5 43.3 96H21.3C9.6 320 0 310.4 0 298.7M405.3 320h-.7c26.6-23.5 43.3-57.8 43.3-96c0-7.6-.7-15-1.9-22.3c13.6-6.3 28.7-9.7 44.6-9.7h42.7c58.9 0 106.7 47.8 106.7 106.7c0 11.8-9.6 21.3-21.3 21.3H405.4zM224 224a96 96 0 1 1 192 0a96 96 0 1 1-192 0m-96 261.3c0-73.6 59.7-133.3 133.3-133.3h117.3c73.7 0 133.4 59.7 133.4 133.3c0 14.7-11.9 26.7-26.7 26.7H154.6c-14.7 0-26.7-11.9-26.7-26.7z"
                    />
                  </svg>
                  <strong class="ml-2"> {{ item.total_leads }} </strong>
                </template>
                <template #content> Prospectos </template>
              </fwb-tooltip>
            </div>

            <div class="text-sm cursor-pointer">
              <fwb-tooltip>
                <template #trigger>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20px"
                    height="20px"
                    viewBox="0 0 48 48"
                    class="float-left text-lime-700"
                  >
                    <path
                      fill="none"
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M24.04 42.5c10.215 0 18.46-8.285 18.46-18.54c0-10.215-8.245-18.46-18.46-18.46C13.785 5.5 5.5 13.745 5.5 23.96c0 10.255 8.285 18.54 18.54 18.54m16.012-27.75H7.96m32.195 18.275H7.857M5.6 24h36.8M24.04 5.5v37"
                    />
                    <path
                      fill="none"
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M24.02 42.5c5.108 0 9.23-8.285 9.23-18.54c0-10.215-4.122-18.46-9.23-18.46c-5.128 0-9.27 8.245-9.27 18.46c0 10.255 4.142 18.54 9.27 18.54"
                    />
                  </svg>
                  <strong class="ml-2">{{ item.total_views }}</strong>
                </template>
                <template #content> Visitas </template>
              </fwb-tooltip>
            </div>

            <div class="text-sm cursor-pointer">
              <fwb-tooltip>
                <template #trigger>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20px"
                    height="20px"
                    viewBox="0 0 48 48"
                    class="float-left text-black"
                  >
                    <path
                      fill="none"
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="m15.107 20.294l2.957-2.957a4.9 4.9 0 0 0 1.244-4.87a24 24 0 0 1-.881-4.384C18.27 6.6 16.977 5.5 15.488 5.5H8.58c-1.777 0-3.145 1.535-2.989 3.304c1.575 17.829 15.777 32.03 33.606 33.606c1.77.156 3.304-1.207 3.304-2.984v-6.16c0-2.248-1.102-3.536-2.583-3.693a24 24 0 0 1-4.384-.88a4.9 4.9 0 0 0-4.87 1.243l-2.957 2.957"
                    />
                    <path
                      fill="none"
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M11.393 5.5c0 2.627.325 5.179.938 7.616C15.728 26.614 27.947 36.608 42.5 36.608"
                    />
                  </svg>
                  <strong class="ml-2">{{ item.total_click_call }}</strong>
                </template>
                <template #content> Clics llamada </template>
              </fwb-tooltip>
            </div>

            <div class="text-sm cursor-pointer">
              <fwb-tooltip>
                <template #trigger>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20px"
                    height="20px"
                    viewBox="0 0 256 258"
                    class="float-left"
                  >
                    <defs>
                      <linearGradient
                        id="logosWhatsappIcon0"
                        x1="50%"
                        x2="50%"
                        y1="100%"
                        y2="0%"
                      >
                        <stop offset="0%" stop-color="#1faf38" />
                        <stop offset="100%" stop-color="#60d669" />
                      </linearGradient>
                      <linearGradient
                        id="logosWhatsappIcon1"
                        x1="50%"
                        x2="50%"
                        y1="100%"
                        y2="0%"
                      >
                        <stop offset="0%" stop-color="#f9f9f9" />
                        <stop offset="100%" stop-color="#fff" />
                      </linearGradient>
                    </defs>
                    <path
                      fill="url(#logosWhatsappIcon0)"
                      d="M5.463 127.456c-.006 21.677 5.658 42.843 16.428 61.499L4.433 252.697l65.232-17.104a123 123 0 0 0 58.8 14.97h.054c67.815 0 123.018-55.183 123.047-123.01c.013-32.867-12.775-63.773-36.009-87.025c-23.23-23.25-54.125-36.061-87.043-36.076c-67.823 0-123.022 55.18-123.05 123.004"
                    />
                    <path
                      fill="url(#logosWhatsappIcon1)"
                      d="M1.07 127.416c-.007 22.457 5.86 44.38 17.014 63.704L0 257.147l67.571-17.717c18.618 10.151 39.58 15.503 60.91 15.511h.055c70.248 0 127.434-57.168 127.464-127.423c.012-34.048-13.236-66.065-37.3-90.15C194.633 13.286 162.633.014 128.536 0C58.276 0 1.099 57.16 1.071 127.416m40.24 60.376l-2.523-4.005c-10.606-16.864-16.204-36.352-16.196-56.363C22.614 69.029 70.138 21.52 128.576 21.52c28.3.012 54.896 11.044 74.9 31.06c20.003 20.018 31.01 46.628 31.003 74.93c-.026 58.395-47.551 105.91-105.943 105.91h-.042c-19.013-.01-37.66-5.116-53.922-14.765l-3.87-2.295l-40.098 10.513z"
                    />
                    <path
                      fill="#fff"
                      d="M96.678 74.148c-2.386-5.303-4.897-5.41-7.166-5.503c-1.858-.08-3.982-.074-6.104-.074c-2.124 0-5.575.799-8.492 3.984c-2.92 3.188-11.148 10.892-11.148 26.561s11.413 30.813 13.004 32.94c1.593 2.123 22.033 35.307 54.405 48.073c26.904 10.609 32.379 8.499 38.218 7.967c5.84-.53 18.844-7.702 21.497-15.139c2.655-7.436 2.655-13.81 1.859-15.142c-.796-1.327-2.92-2.124-6.105-3.716s-18.844-9.298-21.763-10.361c-2.92-1.062-5.043-1.592-7.167 1.597c-2.124 3.184-8.223 10.356-10.082 12.48c-1.857 2.129-3.716 2.394-6.9.801c-3.187-1.598-13.444-4.957-25.613-15.806c-9.468-8.442-15.86-18.867-17.718-22.056c-1.858-3.184-.199-4.91 1.398-6.497c1.431-1.427 3.186-3.719 4.78-5.578c1.588-1.86 2.118-3.187 3.18-5.311c1.063-2.126.531-3.986-.264-5.579c-.798-1.593-6.987-17.343-9.819-23.64"
                    />
                  </svg>
                  <strong class="ml-2">{{ item.total_click_wp }}</strong>
                </template>
                <template #content> Clics en whatsapp </template>
              </fwb-tooltip>
            </div>
          </div>

          <div class="flex flex-wrap mt-4">
            <div class="flex w-1/2 justify-center items-center">
              <button 
                class=" rounded-sm p-2 text-black border-tuin_base-0  border-2" 
                @click="openProspects(item.property_id)" 
              > 
                Asignar prospecto 
              </button>
            </div>

            <div               
              class="flex w-1/2 justify-center items-center">
              <button 
                class=" rounded-sm bg-tuin_base-0 p-2 text-white" 
                @click="goTo('edit', item.property_id)" 
              > 
                Editar 
              </button>              
            </div>

            <div 
              v-if="item.status_id != null && item.status_id == 1"
              class="flex w-full justify-end items-end mt-2 cursor-pointer"
            >
              <a :href="item.slug_url" class=" pointer underline " target="_blank"> Ver publicación   </a>
            </div>
          </div>
          
        </div>
      </fwb-card>    
    </div>

    <div 
      v-if="properties.length > 0"
      class="flex w-full justify-center items-center mt-10 mb-10"
    >
      <fwb-pagination v-model="currentPage" :total-pages="totalPages" show-icons @click="changePage()"></fwb-pagination>
    </div>  

    <div
      v-if="properties.length == 0"
      class="flex flex-col rounded-md w-full h-mx-auto bg-white shadow-xl mt-4 p-4"
    >
      <div class="grid grid-cols-1 gap-4 p-2 mt-5">
        <div class="flex flex-col justify-center items-center">
          <span
            class="flex flex-1 justify-center items-center text-3xl font-bold"
          >
            Aún no tienes inmuebles registrados
          </span>
          <span class="flex justify-center items-center text-xl">
            Haz clic en el bóton para dar de alta una propiedad
          </span>
          <router-link
            :to="{ name: 'propertyForm' }"
            class="flex items-center space-x-2 text-white bg-tuin-100 hover:bg-gray-700 p-2 rounded mt-10 mb-10"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="1.5em"
              height="1.5em"
              viewBox="0 0 16 16"
            >
              <g fill="white">
                <path
                  d="M8.707 1.5a1 1 0 0 0-1.414 0L.646 8.146a.5.5 0 0 0 .708.708L2 8.207V13.5A1.5 1.5 0 0 0 3.5 15h4a.5.5 0 1 0 0-1h-4a.5.5 0 0 1-.5-.5V7.207l5-5l6.646 6.647a.5.5 0 0 0 .708-.708L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293z"
                />
                <path
                  d="M16 12.5a3.5 3.5 0 1 1-7 0a3.5 3.5 0 0 1 7 0m-3.5-2a.5.5 0 0 0-.5.5v1h-1a.5.5 0 0 0 0 1h1v1a.5.5 0 1 0 1 0v-1h1a.5.5 0 1 0 0-1h-1v-1a.5.5 0 0 0-.5-.5"
                />
              </g>
            </svg>
            <span class="ml-2"> Agregar nueva propiedad </span>
          </router-link>
        </div>
      </div>
    </div>
    
    <ModalLeadsComponent
      v-if="leadModal"
      :property-id="dbPropertyId" 
      :show-modal="leadModal"      
      @trigger-update="EventCloseModal"  
    />
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from "vue";
import axios from "axios";
import {
  FwbCard,
  FwbInput,
  FwbTooltip,
  FwbPagination
} from "flowbite-vue";

import LabelTag from "@/commond/LabelTag.vue";
import LoaderTuin from "@/commond/LoaderTuin.vue";
import ModalLeadsComponent from "./modal/ModalLeadsComponent.vue";

import { useStore } from "vuex";
const store = useStore();
let session = computed(() => store.getters.getUserSession);

import { useRouter } from "vue-router";

let router = useRouter();

let properties = ref([]);
let filteredProperties = ref([]);

// let error = ref({});
let searchQuery = ref(null);
let loader = ref(false);

let dbPropertyId = ref(null);
let leadModal = ref(false);

let currentPage = ref(1);
let totalPages  = ref(1);

const topElement = ref(null);

onMounted(async () => {
  store.dispatch("updateStep", 3);
  await get_propierties();
  topElement.value = document.getElementById('top');
});

async function get_propierties() {
  loader.value = true;
  try {
    let url_cnn = '';
    
    url_cnn = `${process.env.VUE_APP_API_BASE_URL}/broker/properties/${session.value.id}?page=${currentPage.value}`;

    var options = {
      headers: {
        "Content-Type": "application/json", // Es importante establecer el tipo de contenido para el envío de archivos
        Authorization: `Bearer ${session.value.token_api}`, // Reemplaza tuTokenBearer con tu token real
      },
    };

    const response = await axios.get(url_cnn, options);

    if (response.status == 200) {
      properties.value = response.data.resp;
      filteredProperties.value = response.data.resp;
      
      let pages = response.data.pages;
      currentPage.value = pages.current_page;
      totalPages.value  = pages.last_page;
      scrollToTop()
    }

    loader.value = false;
  } catch (error) {
    console.error('ERROR', error)
    loader.value = false;
    if (error.response && error.response.status === 401) {
      store.dispatch("clearUserSession");
      router.push({ name: "login" });
    }
  }
}

const scrollToTop = () => {
  if (topElement.value) {
    topElement.value.scrollIntoView({ behavior: 'smooth' });
  }
};


async function changePage(){
  console.log('PAGE',currentPage.value)  
  get_propierties();
}

async function filterProperties() {
  const query = searchQuery.value.toLowerCase();

  properties.value = filteredProperties.value.filter((property) => {
    return Object.keys(property).some((key) => {
      if (typeof property[key] === "string") {
        return property[key].toLowerCase().includes(query);
      }
      return false;
    });
  });
}

async function goTo(state, property_id) {
  router.push({ name: "propertyFormEdit", params: { id: property_id } });
}

async function openProspects(propertyId) {
  console.log('propiedad',propertyId)
  dbPropertyId.value  = propertyId;
  leadModal.value   = true;
}

async function EventCloseModal(){
  console.log('cerro la modal')
  dbPropertyId.value  = null;
  leadModal.value     = false;
}

</script>

<style scoped>
.alig-options{
  float: right;
}
</style>