<template>
  <div>    
    <ToastAlert 
      :is-active="toastAlert" 
      :type="typeMsg" 
      :message="msg" 
    />
    
    <!-- row init -->
    <div
      class="p-2 flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-4"
    >
      <div 
        v-if=" ![ 30 ].includes( type_property_id ) "
        class="flex flex-1 justify-center items-center mt-0 p-2"
      >
        <fwb-input
          v-model="age"
          type="number"
          label="Antigüedad "
          placeholder="ej. 10"
          class="w-full pt-[-10px]"
        />
      </div>

      <div 
        v-if=" ![ 3, 4, 6, 8 ].includes( type_property_id ) "
        class="flex flex-1 justify-center items-center mt-5 p-2"
      >
        <fwb-input
          v-model="bedrooms"
          type="number"
          label="Recámaras *"
          placeholder="ej. 4"
          class="w-full pt-[-10px]"
        />
      </div>

      <div 
        v-if=" ![ 4, 6 ].includes( type_property_id ) " 
        class="flex flex-1 justify-center items-center mt-5 p-2"
      >
        <fwb-input
          v-model="bathrooms"
          type="number"
          :label=" ![ 8].includes( type_property_id ) ? 'Baños *': 'Baños'"
          placeholder="ej. 3"
          class="w-full pt-[-10px]"
        />
      </div>

      <div 
        v-if=" ![ 3, 4, 6 ].includes( type_property_id ) " 
        class="flex flex-1 justify-center items-center mt-5 p-2"
      >
        <fwb-input
          v-model="half_bathrooms"
          type="decimal"
          :label=" ![ 8].includes( type_property_id ) ? 'Medios baños *': 'Medios baños'"
          placeholder="ej. 1"
          class="w-full pt-[-10px]"
        />
      </div>
    </div>
    <!-- row end -->

    <!-- row init -->
    <div
      class="p-2 flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-4"
    >
      <div class="flex flex-1 justify-center items-center mt-0 p-2">
        <div class="flex-auto">
          <label
            for="phone"
            class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >Área metros *</label>
          <InputNumber 
            v-model="constructed_metres" 
            input-id="constructed_metres" 
            :min-fraction-digits="2" 
            :max-fraction-digits="5" 
          />
        </div>
      </div>

      <div class="flex flex-1 justify-center items-center mt-5 p-2">
        <div class="flex-auto">
          <label
            for="phone"
            class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >Metros construidos *</label>
          <InputNumber 
            v-model="meters_build" 
            input-id="meters_build" 
            :min-fraction-digits="2" 
            :max-fraction-digits="5" 
          />
        </div>
      </div>

      <div 
        v-if=" ![ 2, 4, 6, 8 ].includes( type_property_id ) " 
        class="flex flex-1 justify-center items-center mt-5 p-2"
      >
        <fwb-input
          v-model="floor"
          type="number"
          label="Pisos "
          placeholder="Ej. 2"
          class="w-full pt-[-10px]"
        />
      </div>

      <div 
        v-if=" [ 2, 9 ].includes( type_property_id ) " 
        class="flex flex-1 justify-center items-center mt-5 p-2"
      >
        <fwb-input
          v-model="floor"
          type="number"
          label="Piso en el que se ubica "
          placeholder="Ej. 2"
          class="w-full pt-[-10px]"
        />
      </div>

      <div 
        v-if=" ![ 4, 6 ].includes( type_property_id ) "
        class="flex flex-1 justify-center items-center mt-5 p-2"
      >      
        <fwb-input
          v-model="parking"
          type="number"
          label="Estacionamientos "
          placeholder="ej. 2"
          class="w-full pt-[-10px]"
        />
      </div> 
    </div>
    <!-- row end -->


    <!-- editor description  -->
    <div class="p-2 flex flex-col-1 mt-5">
      <div class="flex-auto">
        <label
          for="phone"
          class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          Descripción de propiedad *
        </label>
        <ckeditor
          v-model="property_description"
          :editor="editor"
          :config="editorConfig"
        />
      </div>
    </div>

    <!-- editor characters  -->
    <div class="p-2 flex flex-col-1">
      <div class="flex-auto">
        <label
          for="phone"
          class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          Características adicionales
        </label>
        <ckeditor
          v-model="characteristics"
          :editor="editor"
          :config="editorConfig"
          class="custom-editor"
        />
      </div>
    </div>

    <!-- btn save -->
    <div class="p-2 flex flex-col">
      <div class="flex flex-1 justify-end items-end mt-5 p-2">
        <fwb-button 
          class="bg-tuin-100 hover:bg-tuin-100" 
          size="xl"
          @click="save()"
        >
          Guardar
        </fwb-button>
      </div>
    </div>
    <!-- btn save -->
  </div>
</template>
<script setup>
    import { ref, onMounted, computed, defineProps, watch, defineEmits } from "vue";
    import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
    import ToastAlert from "@/commond/ToastAlert.vue";
    import { FwbInput, FwbButton } from "flowbite-vue";
    import InputNumber from 'primevue/inputnumber';
    import axios from "axios";
    import { useStore } from "vuex";
    const store = useStore();
    let session = computed(() => store.getters.getUserSession);
    
    const props = defineProps({
      resp: {
        type: Object,
        required: true,
        default: () => ({ property_name: '' })
      },
      propertyId: {
        type: Number,
        required: true,
        default: 0
      }
    });

    const emit = defineEmits(['triggerUpdate']);

    let toastAlert = ref(false);
    let typeMsg = ref("");
    let msg = ref("");


    // Configurar CKEditor    
    let age                 = ref('');
    let bedrooms            = ref('');
    let bathrooms           = ref('');
    let parking             = ref('');
    let half_bathrooms      = ref('');
    let floor               = ref(0);
    let location_floor      = ref(0); 
    let constructed_metres  = ref(0);
    let meters_build        = ref(0);
    let property_description= ref("<p> </p>");
    let characteristics     = ref("<p> </p>");

    const editor            = ref(ClassicEditor);
    const editorConfig = {
    toolbar: [
        "heading",
        "|",
        "bold",
        "italic",
        "underline",
        "strikethrough",        
        "blockQuote",
        "|",
        "undo",
        "redo",
        "|",
        "numberedList",
        "bulletedList",
        "|",
        "alignment",
        "indent",
        "outdent",
        "|",

        "insertHR",
        "code",
        "codeBlock",
        "removeFormat",
    ],
    height: "500px",
    };
    async function  validationForm(){
      //  Validaciones para 1	- Casa  
      //  Validaciones para 2	-	Departamento
      //  Validaciones para 3	-	Oficina
      //  Validaciones para 5	-	Consultorio
      //  Validaciones para 7	-	Local
      //  Validaciones para 8	-	Bodega
      //  Validaciones para 9	-	Desarrollo
      if([ 1,2,3,5,7,8, 9 ].includes( type_property_id ))
      {
        if( ![ 3, 5, 7, 8 ].includes( type_property_id ) ){

          if(bedrooms.value  == undefined || bedrooms.value  == ''){
            return { "status": false, "message": "Debes ingresar cantidad de recámaras."}    
          }

          if(bathrooms.value  == undefined || bathrooms.value  == ''){
            return { "status": false, "message": "Debes ingresar cantidad de baños."}    
          }
        }
        
        if(constructed_metres.value  == undefined || constructed_metres.value  == ''){
          return { "status": false, "message": "Debes ingresar el área en metros."}    
        }
        
        if(meters_build.value  == undefined || meters_build.value  == ''){
          return { "status": false, "message": "Debes ingresar los metros construidos"}    
        }

        if(property_description.value  == undefined || property_description.value  == ''){
          return { "status": false, "message": "Debes ingresar descripción del inmueble "}    
        }

      }
      
      //  Validaciones para 4	-	Terreno      
      //  Validaciones para 6	-	Lote            
      
      if([ 4, 6 ].includes( type_property_id ))
      {
        if(constructed_metres.value  == undefined || constructed_metres.value  == ''){
          return { "status": false, "message": "Debes ingresar el área en metros."}    
        }
        
        if(meters_build.value  == undefined || meters_build.value  == ''){
          return { "status": false, "message": "Debes ingresar los metros construidos"}    
        }
      }

      return {'status': true, 'message': 'Ok'}
    }

    async function save() {
      let is_valid = await validationForm();

      const plainText = property_description.value.replace(/<\/?[^>]+(>|$)|&nbsp;/g, ' ');

      const formattedText = plainText.replace(/<br>/g, '\n').trim();
      console.log('----',formattedText);

      if (!is_valid['status'] ){
        showToast('danger', is_valid['message']);
        return 
      }

      let post_params = {
        age:                      age.value,
        bedrooms:                 bedrooms.value,
        bathrooms:                bathrooms.value,
        parking:                  parking.value,
        half_bathrooms:           half_bathrooms.value,
        constructed_metres:       constructed_metres.value,
        meters_build:             meters_build.value,
        property_description:     property_description.value,
        summary:                  formattedText,
        characteristics:          characteristics.value,
        floor:                    floor.value,
        location_floor:           location_floor.value,
        type_property_id:         props.resp && props.resp.general ? props.resp.general.type_property_id:''
      }

      if(props.propertyId != 0 ){
        post_params['property_id'] = props.propertyId
      }

      let url = `${process.env.VUE_APP_API_BASE_URL}/broker/property/${props.propertyId}/save_characteristics`;
      let options = {
        headers: {
          "Content-Type": "application/json",
          'Authorization': `Bearer ${session.value.token_api}`
        },
      };

      try {
        const response = await axios.post( url, post_params, options);

        if (response.status == 401) {                        
          console.log("Hay que sacarlo de la sesion ");
        }

        if (response.status == 200) {
          showToast('success', response.data.message )
          emit('triggerUpdate', response.data.resp.id );
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
      
    }

    async function closeToast() {
      toastAlert.value = false;
      typeMsg.value = "";
      msg.value = "";
    }

    async function showToast(type, message){
      toastAlert.value = true;
      typeMsg.value = type;
      msg.value = message;

      setTimeout(() => {
        closeToast()
      }, 3000);
    }

    onMounted(() => {
        const inputElement = document.getElementById('constructed_metres')
        
        if (inputElement) {
            inputElement.className = 'w-full pt-[-10px] bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 p-2.5 text-sm'
        }

        const inputElement_2 = document.getElementById('meters_build')
        
        if (inputElement) {
            inputElement_2.className = 'w-full pt-[-10px] bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 p-2.5 text-sm'
        }
    })

    let type_property_id = ref(null);
    watch(      
      () => [props.resp.characters, props.resp] ,  
      async ([newVal, obj_resp]) => {
        type_property_id.value = obj_resp && obj_resp.general  ? obj_resp.general.type_property_id : 0;
        
        age.value                 = newVal && newVal.age                  != null ? newVal.age : '';
        bedrooms.value            = newVal && newVal.bedrooms             != null ? newVal.bedrooms : '';
        bathrooms.value           = newVal && newVal.bathrooms            != null ? newVal.bathrooms : '';
        half_bathrooms.value      = newVal && newVal.half_bathrooms       != null ? newVal.half_bathrooms : '';
        constructed_metres.value  = newVal && newVal.constructed_metres   != null ? newVal.constructed_metres : '';
        meters_build.value        = newVal && newVal.meters_build         != null ? newVal.meters_build : '';
        property_description.value= newVal && newVal.property_description != null ? newVal.property_description : "<p> </p>";
        characteristics.value     = newVal && newVal.characteristics      != null ? newVal.characteristics : "<p> </p>"; 
      },
      { immediate: true }
    );
</script>

<style scoped>
  .custom-editor .ck-editor__editable {
    min-height: 300px; /* Ajusta a la altura deseada */
    max-height: 600px; /* Opcional: Define un límite máximo */
    overflow: auto; /* Para manejar contenido largo */
  }
</style>


