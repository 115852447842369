<template>
  <div class="mt-4">
    <fwb-heading tag="h3">
      Amenidades
    </fwb-heading>

    <div class="mt-4">
      <ul class="grid grid-cols-4 gap-4">
        <li
          v-for="item in props.list"
          :key="item.id"
          class="flex items-center"
        >
          <div v-html="item.catalogue_amenities.icon" />
          <span class="ml-2"> {{ item.catalogue_amenities.name }} </span>
        </li>
      </ul>
    </div>
  </div>
</template>
<script setup>
import { FwbHeading } from "flowbite-vue";
const props = defineProps({
  list: {
    type: Array,
    required: true,
  },
});
</script>