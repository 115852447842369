<template>
  <div>
    <!-- Loader  -->
    <div
      v-if="loader"
      class="fixed inset-0 flex items-center justify-center bg-black  z-50"
    >
      <img
        src="@/assets/imgs/tuin_loader.gif"
        alt="cargando..."
        class=" w-[500px]"
      >
    </div>
    <!-- Loader  -->

    <ToastAlert
      :is-active="toastAlert"
      :type="typeMsg"
      :message="msg"
    />
    <div class="flex min-h-screen bg-dark_bg-100 text-white h-full">
      <div class="hidden w-1/2 sm:flex items-center justify-center">
        <div class="mt-20">
          <img
            src="@/assets/imgs/door.jpg"
            alt="Tuin hora de hacer magia"
            class="w-full h-full max-h-screen rounded-lg shadow-lg"
          >
        </div>
      </div>

      <div
        v-if="page_view == 'login'"
        class="w-full sm:w-1/2 flex flex-col items-center justify-center bg-black min-h-screen"
      >
        <img
          src="@/assets/imgs/LogoTuin.svg"
          alt="Logo"
          class="object-contain"
        >
        <h3 class="mb-6">
          Asesores
        </h3>
        <!-- card -->
        <div class="w-3/4 max-w-md bg-dark_bg-100 p-5 rounded-sm">
          <h2 class="text-2xl font-semibold mb-6">
            Recuperar contraseña
          </h2>
          <div class="mb-4">
            <fwb-input
              v-model="email"
              label="Email"
              type="email"
              required=""
              label-class="text-white"
              placeholder="ej mail@mail.com"
              class="custom-label"
            />
          </div>
          
          
          <button
            class="w-full py-2 bg-tuin-100 hover:bg-tuin-100 rounded-md text-white font-semibold"
            @click="Restore()"
          >
            Recuperar
          </button>
          <div class="mt-4 text-center">
            <router-link
              to="/login"
              class="text-tuin-100 text-sm cursor-pointer"
            >
              Ya tengo una cuenta 
            </router-link>
          </div>
        </div>
      </div>
    </div>    
  </div>
</template>

<script setup>
import { ref } from "vue";
import { FwbInput } from "flowbite-vue";
import axios from "axios";
import ToastAlert from "@/commond/ToastAlert.vue";
import { useRouter } from "vue-router";
let router = useRouter();

let page_view = ref("login");

let email       = ref("");
let toastAlert  = ref(false);
let typeMsg     = ref("");
let msg         = ref("");

let loader = ref(false);

async function Restore() {
  if (email.value == "") {
    showToast('danger', 'Debes agregar un email válido')
    return false;
  }

  let params = { email: email.value, type_account: 3 };
  try {
    const response = await axios.post(
      `${process.env.VUE_APP_API_BASE_URL}/restore_account`,
      params
    );

    if (response.status == 200) {      
      showToast('success', response.data.message )
      setTimeout(() => {
        router.push({ name: "login" });  
      }, 2000);
      
      return false;
    }

  } catch (error) {
    if (error.response.status == 422) {
      showToast('danger', 'No se pudo concretar')
    }
  }
}

async function showToast( type = null, message = null){
  toastAlert.value  = true;
  typeMsg.value     = type;
  msg.value         = message;

  setTimeout(() => {
    closeToast();
  }, 3000);
}

async function closeToast() {
  toastAlert.value = false;
  typeMsg.value = "";
  msg.value = "";
}

</script>
  
<style scoped>
/* Si el label tiene clases predeterminadas de Flowbite/Tailwind, puedes sobrescribirlas */
::v-deep .custom-label label {
  @apply text-white;
}

/* También puedes apuntar directamente al selector completo */
::v-deep .custom-label label.block.mb-2.text-sm.font-medium {
  @apply text-white;
}
</style>