<template>
  <div>
    <div
      class="flex flex-col items-center  min-h-screen bg-white text-black"
    >
      <!-- Logo -->
      <div class="mb-4 mt-4">
        <img
          :src="company_src" 
          :alt="company_name"
          class="w-24 h-24 rounded-full border-4 border-white"
        >
      </div>

      <!-- Nombre de la inmobiliaria -->
      <h1 class="text-lg font-medium mb-2"> 
        {{ company_name }} 
      </h1>

      <!-- Foto del agente -->
      <div class="mb-4">
        <img
          :src="broker_src" 
          :alt="broker_name"
          class="w-24 h-24 rounded-full border-4 border-white"
        >
      </div>

      <!-- Nombre del agente -->
      <h2 class="text-base font-semibold mb-6">
        {{ broker_name }} 
      </h2>

      <!-- Botones -->
      <div class="flex flex-col gap-3 w-3/4">
        <!-- Botón WhatsApp -->
        <a
          :href="broker_wp"
          target="_blank"
          class="flex items-center justify-center py-3 bg-green-400 text-white font-bold rounded-lg hover:bg-green-500 transition"
          @click="callActionLog(property_id, 44, detail_message_wp)"          
        >
          
          <img
            src="@/assets/imgs/icons/wp.svg"
            alt="cargando..."
            class="w-10 h-5 mr-2"
          >
          WhatsApp
        </a>

        <!-- Botón Llamada -->
        <a
          :href="broker_phone"
          target="_blank"
          class="flex items-center justify-center py-3 bg-red-500 text-white font-bold rounded-lg hover:bg-red-600 transition"
          @click="callActionLog(property_id, 45, detail_message_call)"
        >
          <img
            src="@/assets/imgs/icons/phone.svg"
            alt="Llamada asesor inmobiliario"
            class="w-10 h-5 mr-2"
          >
          Llamada
        </a>
      </div>
    </div>
  </div>
</template>
<script setup>
  import { defineProps, ref, watch, computed } from "vue";
  import axios from "axios";
  import { useStore } from "vuex";
  const store = useStore();
  let session = computed(() => store.getters.getUserSession);

  const props = defineProps({
      broker: {
        type: Object,
        required: true,
        default: () => ({ property_name: '' })
      },
      propertyId:{
        type: Number,
        required: true,
        default: null,
      }
  });

  let company_src = ref(require("@/assets/imgs/avatar_default.png"));
  let broker_src = ref(require('@/assets/imgs/default_company.png'));
  let company_name = ref('tuin');
  let broker_name = ref('');
  let broker_phone = ref('');
  let broker_wp = ref('');
  let property_id = ref('');

  let detail_message_wp = ref('');
  let detail_message_call = ref('');

  async function callActionLog(property_id, type_action, detail){

    let link = `${process.env.VUE_APP_API_BASE_URL}/event/add_log`;
    let param_analytics = {
        "property_id":          property_id,
          "agent_id":           session.value.id,
          "activity_id":        type_action,
          "description":        detail,          
    }

    let options = {
      headers: {
        "Content-Type": "application/json"
      },
    };

    try {
      const response = await axios.post(link, param_analytics, options);

      if (response.status == 401) {
        //console.log("Hay que sacarlo de la sesion ");
      }

      if (response.status == 200) {
        console.log(response);
      }
      
    } catch (error) {
      console.error("Error fetching data:", error);
    }

  }

  watch(      
      () => [props.broker, props.propertyId] ,  
      async ([newVal, newValProperty ]) => {
        
        property_id.value = newValProperty;

        detail_message_wp.value = `Contacto vía whatsapp Asesor inmobiliario contacta: ${session.value.name} con id:${session.value.id} contacto al asesor inmobiliario contactado: ${newVal.full_name} con id:${newVal.id}`;
        detail_message_call.value = `Contacto vía llamada Asesor inmobiliario contacta: ${session.value.name} con id:${session.value.id} contacto al asesor inmobiliario contactado: ${newVal.full_name} con id:${newVal.id}`;

        if(newVal.company && newVal.company.logo){
          company_src.value = newVal.company.logo 
          company_name.value                = newVal && newVal.company.name                   != null ? newVal.company.name : 'Tuin';
        }

        if(newVal && newVal.profile_avatar){
          broker_src.value = newVal.profile_avatar;
        }

          
          broker_name.value                 = newVal && newVal.full_name                      != null ? newVal.full_name : '';
          broker_phone.value                = newVal && newVal.phone                          != null ? 'tel:+'+newVal.phone : '';   
          broker_wp.value                   = newVal && newVal.phone                          != null ?  `https://wa.me/52${ newVal.phone   }?text=Hola%20he%20visto%20tu%20propiedad%20en%20Tuin%20y%20quisiera%20más%20información%20`: '';          
      },
      { immediate: true }
    );
  
</script>