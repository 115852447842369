<template>
  <div>
    <fwb-modal
      size="5xl"
      @close="cerrar"
    >
      <template #header>
        <h3 class="text-xl font-semibold">
          Asignar propiedad
        </h3>
      </template>
      <template #body>
        <!-- Loader -->
        <div
          v-show="loader"
          class="flex flex-col rounded-md w-full h-full bg-white shadow-xl mt-4 p-4 justify-center items-center" 
        >
          <img
            src="@/assets/imgs/tuin_loader.gif"
            alt="cargando..."
            class="w-[500px]"
          >
        </div>
        <!-- Loader -->
        <!-- Contenido dinámico basado en el ID -->
        <div
          v-show="!loader"
          class="flex flex-col rounded-md w-full h-full bg-white shadow-xl mt-4 p-4"
        >
          <div
            class="relative overflow-hidden shadow-md rounded-lg t w-full h-screen"
          >
            <table class="table-fixed text-lef">
              <thead class="uppercase bg-[#ff4265] text-[#e5e7eb]">
                <tr>
                  <!--[-->
                  <td
                    class="py-0 border text-center font-bold p-4"
                    contenteditable="true"
                  >
                    &nbsp;
                  </td>
                  <td
                    class="py-0 border text-center font-bold p-4"
                    contenteditable="true"
                  >
                    &nbsp;
                  </td>
                  <td
                    class="py-0 border text-center font-bold p-4"
                    contenteditable="true"
                  >
                    Nombre
                  </td>

                  <td
                    class="py-0 border text-center font-bold p-4"
                    contenteditable="true"
                  >
                    Tipo inmueble
                  </td>
                  <td
                    class="py-0 border text-center font-bold p-4"
                    contenteditable="true"
                  >
                    Tipo de transacción
                  </td>
                  <td
                    class="py-0 border text-center font-bold p-4"
                    contenteditable="true"
                  >
                    Dirección
                  </td>
                  <td
                    contenteditable="true"
                    class="py-0 border text-center font-bold p-4"
                  >
                    Precio
                  </td>

                  <!--]-->
                </tr>
              </thead>
              <tbody
                class="bg-white text-[#646464]"
                style="background-color: #ffffff; color: #646464"
              >
                <!--[-->
                <tr
                  v-for="(row, index) in list"
                  v-show="list.length > 0"
                  :key="index"
                  class="py-0"
                >
                  <!--[-->
                  <td class="py-0 border p-4 text-center">
                    <fwb-checkbox
                      v-model="row.is_checked"
                      @change="addProperty(row.property_id)"
                    />
                  </td>
                  <td class="flex justify-center items-center">
                    <div v-if="row.total_image == 0">
                      <img
                        src="@/assets/imgs/preview_house.svg"
                        width="90"
                        height="90"
                      >
                    </div>
                    <div v-if="row.image">
                      <img
                        :src="row.image"
                        width="90"
                        height="90"
                      >
                    </div>
                  </td>
                  <td class="py-0 border p-4">
                    {{ row.property_name }}
                  </td>

                  <td class="py-0 border p-4">
                    {{ row.type_property_id.name }}
                  </td>

                  <td class="py-0 border p-4">
                    {{ row.type_movement_name }}
                  </td>

                  <td class="py-0 border p-4">
                    {{
                      row.address != ", , " ? row.address : ""
                    }}
                  </td>
                  <td class="py-0 border p-4">
                    <span>
                      {{ row.property_value }} {{ row.currency }}
                    </span>
                  </td>

                  <!--]-->
                </tr>
                <!--]-->

                <tr v-show="list.length == 0">
                  <td
                    colspan="5"
                    class="text-center"
                  >
                    <div class="m-6 flex justify-center items-center">
                      Aún no has dado de alta prospectos.
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <!-- Puedes realizar una llamada a una API o utilizar datos locales -->
      </template>
    </fwb-modal>
  </div>
</template>

<script setup>
import { FwbModal, FwbCheckbox } from "flowbite-vue";
import { watch, ref, computed } from "vue"; 
import axios from "axios";

const props = defineProps({
  // eslint-disable-next-line vue/prop-name-casing
  prospectId: {
    type: Number,
    required: true,
    default: 0,
  },
});

let list = ref([]);
let loader = false;

const emit = defineEmits(["close"]);

function cerrar() {
  emit("close");
}

import { useStore } from "vuex";
const store = useStore();
let session = computed(() => store.getters.getUserSession);

async function getPropertiesLead() {
  try {
    let url_link = `${process.env.VUE_APP_API_BASE_URL}/broker/lead/${session.value.id}/properties_list/${props.prospectId}`;

    let options = {
      headers: {
        Authorization: `Bearer ${session.value.token_api}`,
      },
    };

    const response = await axios.get(url_link, options);

    if (response.status == 401) {
      store.dispatch("clearUserSession");
    }

    if (response.status == 200) {
      list.value = response.data.resp;
    }
  } catch (error) {
    console.error("Error fetching data:", error);
  }
  loader = false;
}

async function addProperty(PropertyId) {
  try {
    let url_link = `${process.env.VUE_APP_API_BASE_URL}/broker/lead/${session.value.id}/add-property`;

    let postParams = {
      property_id: PropertyId,
      lead_id: props.prospectId,
    };

    let options = {
      headers: {
        Authorization: `Bearer ${session.value.token_api}`,
      },
    };

    const response = await axios.post(url_link, postParams, options);

    if (response.status == 401) {
      store.dispatch("clearUserSession");
    }

    if (response.status == 200) {
      console.log(response.message);
    }
  } catch (error) {
    console.error("Error fetching data:", error);
  }
  
}

watch(
  () => props,
  (newVal) => {
    if (newVal.prospectId) {
      // Lógica para cargar datos basados en el nuevo ID
      loader = true;
      getPropertiesLead();
    }
  },
  { immediate: true } // Ejecuta el watch inmediatamente al montar el componente
);
</script>
