<template>
  <div class="mt-4">
    <fwb-heading tag="h3">
      Características
    </fwb-heading>
    <div class=" mt-4">
      <ul class="grid grid-cols-4 gap-4">
        <!-- INfo manual -->
        <li v-if="property.age">
          <div class=" bg-tuin_base-0 p-2 float-left rounded-full">
            <img
              src="@/assets/imgs/icons/grua.svg"
              :alt="antigüedad"
              width="30"
            >
          </div>
          <div class="flex justify-center font-bold"> Antigüedad </div>
          <div class="ml-2 flex justify-center items-center">  {{ property.age }} años </div>
        </li>

        <li v-if="property.constructed_metres">
          <div class=" bg-tuin_base-0 p-2 float-left rounded-full">
            <img
              src="@/assets/imgs/icons/meters_w.svg"
              :alt="Metros"
              width="30"
            >
          </div>
          <div class="flex justify-center font-bold"> Metros </div>
          <div class="ml-2 flex justify-center items-center">  {{ property.constructed_metres }} &#x33A1; </div>
        </li>

        <li v-if="property.meters_build">
          <div class=" bg-tuin_base-0 p-2 float-left rounded-full">
            <img
              src="@/assets/imgs/icons/construccion.svg"
              :alt="Construcción"
              width="30"
            >
          </div>
          <div class="flex justify-center font-bold"> Construcción </div>
          <div class="ml-2 flex justify-center items-center">  {{ property.meters_build }} &#x33A1; </div>
        </li>

        <li v-if="property.floor">
          <div class=" bg-tuin_base-0 p-2 float-left rounded-full">
            <img
              src="@/assets/imgs/icons/floor.svg"
              :alt="pisos"
              width="30"
            >
          </div>
          <div class="flex justify-center font-bold"> Pisos </div>
          <div class="ml-2 flex justify-center items-center">  {{ property.floor }} </div>
        </li>

        <li v-if="property.bedrooms">
          <div class=" bg-tuin_base-0 p-2 float-left rounded-full">
            <img
              src="@/assets/imgs/icons/habitacion.svg"
              :alt="Habitaciones"
              width="30"
            >
          </div>
          <div class="flex justify-center font-bold"> Habitaciones </div>
          <div class="ml-2 flex justify-center items-center">  {{ property.bedrooms }} </div>
        </li>

        <li v-if="property.bathrooms">
          <div class=" bg-tuin_base-0 p-2 float-left rounded-full">
            <img
              src="@/assets/imgs/icons/shower_w.svg"
              :alt="Baños"
              width="30"
            >
          </div>
          <div class="flex justify-center font-bold"> Baños </div>
          <div class="ml-2 flex justify-center items-center">  {{ property.bathrooms }} </div>
        </li>

        <li v-if="property.half_bathrooms">
          <div class=" bg-tuin_base-0 p-2 float-left rounded-full">
            <img
              src="@/assets/imgs/icons/toilet.svg"
              alt="Medio Baños"
              width="30"
            >
          </div>
          <div class="flex justify-center font-bold"> Medio baños </div>
          <div class="ml-2 flex justify-center items-center">  {{ property.half_bathrooms }} </div>
        </li>

        <li v-if="property.parking">
          <div class=" bg-tuin_base-0 p-2 float-left rounded-full">
            <img
              src="@/assets/imgs/icons/parking_w.svg"
              alt="Estacionamiento "
              width="30"
            >
          </div>
          <div class="flex justify-center font-bold"> Estacionamiento </div>
          <div class="ml-2 flex justify-center items-center">  {{ property.parking }} </div>
        </li>

        <!-- INfo manual -->
        <li
          v-for="item in props.list"
          :key="item.id"
          class="flex items-center"
        >
          <div v-html="item.catalogue_character.icon" />
          <span class="ml-2"> {{ item.catalogue_character.name }} </span>
        </li>
      </ul>
    </div>
  </div>
</template>
<script setup>
import { FwbHeading } from "flowbite-vue";
const props = defineProps({
  list: {
    type: Array,
    required: true,
  },
  property: {
      type: Object,
      required: true,
      default: null
    }
});
</script>
