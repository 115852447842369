<template>
  <fwb-navbar class="bg-black">
    <template #logo>
      <fwb-navbar-logo
        alt="Tuin asesores"
        :image-url="logoTuin"
        link="#"
      />
    </template>
    <template #default="{ isShowMenu }">
      <fwb-navbar-collapse
        :is-show-menu="isShowMenu"
        class="text-white bg-black"
      >
        <!-- <fwb-navbar-link
          is-active
          to="/dashboard"
        > 
          Dashboard  
        </fwb-navbar-link> -->
        <fwb-navbar-link to="/bolsa-inmobiliaria">
          Bolsa inmobiliaria
        </fwb-navbar-link>
        <fwb-navbar-link link="/Propiedades">
          Mis Propiedades
        </fwb-navbar-link>

        <fwb-navbar-link to="/Prospectos">
          Prospectos
        </fwb-navbar-link>

        <button
          id="dropdownDefaultButton"
          data-dropdown-toggle="dropdown"
          class="text-black bg-white hover:bg-tuin_base-0 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-3 py-2.5 text-center inline-flex items-center dark:bg-tuin_base-0 dark:hover:bg-tuin_base-0 dark:focus:ring-blue-800"
          type="button"
        >
          Configuración
          <svg
            class="w-2.5 h-2.5 ms-3"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 10 6"
          >
            <path
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="m1 1 4 4 4-4"
            />
          </svg>
        </button>

        <!-- Dropdown menu -->
        <div
          id="dropdown"
          class="z-10 hidden bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700"
        >
          <ul
            class="py-2 text-sm text-gray-700 dark:text-gray-200"
            aria-labelledby="dropdownDefaultButton"
          >
            <li>
              <a
                href="/info-profile"
                class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"                
              >
                Mi perfil
              </a>
            </li>
            <li>
              <a
                href="/info-company"
                class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
              >
                Inmobiliaria
              </a>
            </li>
            <li>
              <a
                href="/change-password"
                class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
              >
                Contraseña
              </a>
            </li>
            <li>
              <a
                href="/info-networks"
                class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
              >
                Redes sociales
              </a>
            </li>
          </ul>
        </div>

        <fwb-navbar-link
          link="#"
          @click="outsession()"
        >
          Salir
        </fwb-navbar-link>
      </fwb-navbar-collapse>
    </template>
  </fwb-navbar>
</template>

<script setup>
//import { includes } from 'core-js/core/array';
import {
  FwbNavbar,
  FwbNavbarCollapse,
  FwbNavbarLink,
  FwbNavbarLogo,
} from "flowbite-vue";

import { useStore } from "vuex";
let store = useStore();
import logoTuin from "@/assets/imgs/LogoTuin.svg";

//let img_logo = includes('@/assets/imgs/LogoTuin.svg')
 
async function outsession() {
  await store.dispatch("clearUserSession"); // Limpia la sesión
  window.location.replace("/login");
}
</script>

<style scoped>
.bg-gray-50 {
  background-color: #000 !important;
}
</style>