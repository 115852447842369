<template>
  <div class="fixed top-10 right-10 z-50">
    <fwb-toast 
      v-show="props.isActive" 
      :type="props.type"
      :class="{
        'bg-red-100 border-2 border-red-300': props.type === 'danger',
        'bg-green-100 border-2 border-green-300': props.type === 'success'
      }"
    > 
      {{ props.message }} 
    </fwb-toast>
  </div>
</template>
  
<script setup>
    import { FwbToast } from "flowbite-vue";
    import {  defineProps } from "vue";
    const props = defineProps({
    message: {
        type: String,
        required: true
    },
    type: {
        type: String,
        required: true
    },
    isActive:{
        type: Boolean,
        required: true
    }
    });
</script>