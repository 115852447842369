<!-- eslint-disable vue/no-v-html -->
<template>
  <div>
    <div
      v-if="loader"
      class="fixed inset-0 flex items-center justify-center bg-white z-50"
    >
      <img
        src="@/assets/imgs/tuin_loader.gif"
        alt="cargando..."
        class="w-[500px]"
      >
    </div>
    <div class="flex flex-wrap">
      <div class="w-full">
        <fwb-carousel :pictures="pictures" />
      </div>
    </div>
    <!-- Columna izquierda -->
    <div class="flex flex-wrap mt-4">
      <div class="w-[69%] p-4 bg-gray-100 rounded-md shadow-md">
        <div class="w-11/12">
          <CardMovementComponent :property="property" />
        </div>

        <div class="flex flex-wrap mt-4">
          <!-- Name property, address -->
          <div class="w-1/2">
            <h1 class="text-left text-xl md:text-3xl font-bold">
              {{ property.property_name }} 
            </h1>
            <p class="text-gray-400 mt-2">
              {{ property.address }}
            </p>
            <hr class="border-b-2 border-tuin_base-0 w-[40%] mt-4">
          </div>
          <!-- Price property -->
          <div
            class="text-tuin_base-0 text-3xl md:text-5xl font-bold mb-8 w-1/2"
          >
            <span v-if="property.type_movement_id == 1">{{ property.property_value }} {{ property.currency }} </span>
            <span v-if="property.type_movement_id == 2">{{ property.rent_value }} {{ property.currency }} </span>
            <div
              v-show="property.include_maintenance == 0 && property.maintenance_value != '$0' "
              class=" text-2xl"
            >
              {{ property.maintenance_value }} {{ property.currency }} (mantenimiento)
            </div>
          </div>
        </div>

        <!-- Caracteristicas section -->
        <div class="flex flex-wrap mt-4">
          <CardCharactersComponent 
            :list="characters_list"
            :property="property" 
          />
        </div>

        <!-- Description -->
        <div
          v-if="description != '<p> </p>'"
          class="flex flex-wrap mt-4"
        >
          <fwb-heading tag="h4">
            Descripción:
          </fwb-heading>
          
          <div v-html="description" />
        </div>

        <!-- Otras caracteristicas -->
        <div
          v-if="characters_additional != '<p> </p>'"
          class="flex flex-wrap mt-4"
        >
          <fwb-heading 
            v-show="characters_additional != null "
            tag="h4"
          > 
            Otras características: 
          </fwb-heading>
          <div 
            v-show="characters_additional != null " 
            v-html="characters_additional" 
          />
        </div>
        
        <!-- Amenidadaes -->
         
        <CardAmenitiesComponent 
          v-show="amenities_list.length > 0"
          :list="amenities_list" 
        />
      </div>
      <!-- Fin Columna izquierda -->
      <!-- Columna derecha -->
      <div class="w-[30%] ml-2 p-4 bg-gray-100 rounded-md shadow-md">
        <!-- Contact -->
        <DetailCardBrokerComponent 
          :broker="property.agent"
          :property-id="proeprtyId"
        />
      </div>
      <!-- Fin Columna derecha -->
    </div>

    <!-- Mapa de ubicación  -->
    <div class="flex flex-wrap mt-4 bg-white p-2">
      <fwb-heading tag="h4">
        Mapa de ubicación
      </fwb-heading>
      <div
        ref="mapRef"
        style="width: 100%; height: 400px"
      />
    </div>
  </div>
</template>
  
<script setup>
// Add any script logic if needed
import { FwbCarousel, FwbHeading } from "flowbite-vue";
import { Loader } from "@googlemaps/js-api-loader";

import { ref, computed, onMounted } from "vue";
import axios from "axios";

import { useRoute } from "vue-router";
const route = useRoute();

import { useStore } from "vuex";
import DetailCardBrokerComponent from "./DetailCardBrokerComponent.vue";
import CardMovementComponent from "./CardMovementComponent.vue";
import CardCharactersComponent from "./CardCharactersComponent.vue";
import CardAmenitiesComponent from "./CardAmenitiesComponent.vue";

const store = useStore();
let session = computed(() => store.getters.getUserSession);

let loader = ref(false);
let proeprtyId = route.params.property_id;
let property = ref({});
let pictures = ref([]);
let description = ref("<p></p>");
let characters_additional = ref("<p></p>");
let characters_list = [];
let amenities_list = [];

async function getDetail() {
  try {
    let options = {
      headers: {
        Authorization: `Bearer ${session.value.token_api}`,
      },
    };

    let link = `${process.env.VUE_APP_API_BASE_URL}/broker/exchange/property/${proeprtyId}`;

    const response = await axios.get(link, options);

    if (response.status == 200) {
      property.value = response.data.info;
      characters_list = response.data.resp.character_list;
      amenities_list = response.data.resp.amenities_list;

      loader.value = false;

      pictures.value = response.data.resp.images;

      description.value = property.value.property_description;
      characters_additional.value = property.value.characteristics;

    }
  } catch (error) {
    console.error("Error fetching data:", error);
  }
}

//let loader_map;
const apiKey = "AIzaSyDh_2CwOWAcS258EwjQsctRq9ypVZfHAZw";
const mapRef = ref(null);

onMounted(async () => {
  loader.value = true;
  await getDetail();
  
  const loader_map = new Loader({
    apiKey: apiKey, // Reemplaza con tu clave de API
    libraries: ["places"], // Importante: Añade 'places' para Autocomplete
  });

  // loader_map.load().then(async () => {
  //   await initMap();
  // });
  try {
    // Espera a que Google Maps cargue
    const google = await loader_map.load();
    
    // Configuración del mapa
    const map = new google.maps.Map(mapRef.value, {
      center: {
        lat: property.value.property_lt,
        lng: property.value.property_ln,
      }, // Coordenadas iniciales (CDMX)
      zoom: 15, // Nivel de zoom
    });

    
    // Añadir un marcador al mapa
    new google.maps.Marker({
      position: {
        lat: property.value.property_lt,
        lng: property.value.property_ln,
      }, // Coordenadas del marcador
      map,
      title: `${property.value.property_name}`, // Texto al pasar el mouse sobre el marcador
      icon: {
        url: "https://tuin.ai/imgs/PinTuin.svg", // URL del ícono personalizado
        scaledSize: new google.maps.Size(50, 50), // Tamaño del ícono
        origin: new google.maps.Point(0, 0), // Origen del ícono
        anchor: new google.maps.Point(25, 50), // Punto de anclaje del ícono
      },
    });
  } catch (error) {
    console.error("Error al cargar Google Maps:", error);
  } finally {
    loader.value = false;
  }
});
</script>
  
<style>
/* Add custom styles if required */
</style>
  