<template>
  <div>    
    <fwb-modal v-if="activeModal" size="5xl" @close="closeModal">
      <template #header>
        <div class="flex items-center text-lg">Asignar prospecto</div>
      </template>
      <template #body>
        <div class="fleax flex-wrap">
          <div 
            v-show="tab == 'list'"
            class="flex justify-end items-center "
          >
            <button 
              class="rounded-lg bg-tuin_base-0 p-2 text-white"
              @click="changeTab"
            >
              Agregar nuevo prospecto
            </button>
          </div>
          <div 
            v-if="Leads.length > 0 && tab == 'list' "
            class="flex flex-1 mt-5 w-full"
          >
            <TableLeadPropertyComponent :list="Leads" :property-id="props.propertyId" />
          </div>

          <div 
            v-if=" tab == 'form' "
            class="flex flex-1 mt-5 w-full"
          >
            <FormLeadPropertyComponent 
            @trigger-update-lead="updateListLead" 
            />
          </div>
        </div>
      </template>
    </fwb-modal>
  </div>
</template>
<script setup>
import { ref, watch, computed } from "vue";
import {  FwbModal } from "flowbite-vue";
import axios from "axios";

import { useStore } from "vuex";
const store = useStore();
let session = computed(() => store.getters.getUserSession);

import { useRouter } from "vue-router";
import TableLeadPropertyComponent from "@/components/leads/TableLeadPropertyComponent.vue";
import FormLeadPropertyComponent from "@/components/leads/FormLeadPropertyComponent.vue";
// import LeadFormView from "@/components/leads/LeadFormView.vue";

let router = useRouter();

const emit = defineEmits(["triggerUpdate"]);

const props = defineProps({
  propertyId: {
    type: Number,
    required: true,
    default: null,
  },
  showModal: {
    type: Boolean,
    required: true,
    default: false,
  },
});

let activeModal = ref(false);
let loader = ref(true);
let Leads = ref([]);
let tab = ref('list');

function closeModal() {
  emit("triggerUpdate");
}

async function getListLeads() {
  loader.value = true;
  let url_cnn = `${process.env.VUE_APP_API_BASE_URL}/broker/lead/${session.value.id}/properties/${props.propertyId}`;

  var options = {
    headers: {
      "Content-Type": "application/json", // Es importante establecer el tipo de contenido para el envío de archivos
      Authorization: `Bearer ${session.value.token_api}`, // Reemplaza tuTokenBearer con tu token real
    },
  };
  try {
    const response = await axios.get(url_cnn, options);
    if (response.status == 200) {
      Leads.value = response.data.resp;
      console.log(Leads);
    }
    // loader.value = false;
  } catch (error) {
    console.error("ERROR", error);
    if (error.response && error.response.status === 401) {
      store.dispatch("clearUserSession");
      router.push({ name: "login" });
    }
    // loader.value = false;
  }
}


function changeTab(){
  tab.value = tab.value == 'list'? 'form':'list';
}

async function updateListLead(){
  await getListLeads();
  await changeTab();
}

watch(
  () => props,
  async (newVal) => {
    activeModal.value = newVal && newVal.showModal;

    if (activeModal.value) {
      getListLeads();
    }
  },
  { immediate: true }
);
</script>
