<template>
  <div class="w-full rounded overflow-hidden shadow-lg bg-white mt-4 mb-24">
    <ToastAlert
      :is-active="toastAlert"
      :type="typeMsg"
      :message="msg"
    />
    <!-- LOADER -->
    <div
      v-if="loader_profile"
      class="fixed inset-0 flex items-center justify-center bg-black  z-50"
    >
      <img
        src="@/assets/imgs/tuin_loader.gif"
        alt="cargando..."
        class=" w-[500px]"
      >
    </div>
    <!-- LOADER -->

    <div 
      v-if="!loader_profile"
      class="p-2 flex flex-col-2"
    >
      <p v-if="step === 0">
        Para continuar, por favor complete los siguientes campos con su
        información
      </p>
      <p v-if="step !== 0">
        Ingresa los datos de tu perfil, para mantener tu contacto actualizado.
      </p>
    </div>

    <div 
      v-if="!loader_profile"
      class="flex flex-col sm:flex-row mt-4 sm:mt-10"
    >
      <!-- Imagen de perfil  -->
      <div class="w-full sm:w-1/3 mb-4 sm:mb-0">
        <!-- Dropzone visible solo si no hay imagen -->
        <div
          :class="[
            'border-4 border-dashed p-6 rounded-md cursor-pointer',
            dragging
              ? 'border-blue-500 bg-blue-100'
              : 'border-gray-300 bg-gray-50',
          ]"
          class="flex flex-col items-center justify-center space-y-4 p-4 m-4"
          @dragover.prevent
          @dragenter.prevent="dragging = true"
          @dragleave="dragging = false"
          @drop.prevent="handleDrop"
          @click="triggerFileInput"
        >
          <div class="flex justify-center items-center m-auto">
            <img
              id="logo_company"
              :src="img_src"
              alt="Logo de copañía"
              width="250"
              height="250"
            >
          </div>
          <p
            v-if="!is_new"
            class="text-gray-500 justify-center items-center"
          >
            Arrastra y suelta archivos aquí tu foto o haz clic para
            seleccionarlo
          </p>
          <p
            v-if="is_new"
            class="text-gray-500 justify-center items-center"
          >
            Haz clic sobre la imagen si deseas cambiar tu foto
          </p>
          <!-- <ul v-else class="list-disc">
          <li v-for="(file, index) in files" :key="index">
            {{ file.name }}
          </li>
        </ul> -->

          <input
            ref="fileInput"
            type="file"
            class="hidden"
            accept="image/*"
            @change="handleFileSelect"
          >
          <button
            class="bg-blue-500 text-white px-4 py-2 rounded hidden"
            @click="triggerFileInput"
          >
            Seleccionar Archivos
          </button>
        </div>
        <!-- Preview de la imagen si ya fue cargada -->
        <div v-if="imagePreview">
          <div class="flex flex-col items-center space-y-4">
            <img
              :src="imagePreview"
              alt="Preview de la imagen"
              class="w-64 h-64 object-cover rounded-md"
            >
            <button
              class="bg-red-500 text-white px-4 py-2 rounded"
              @click="clearImage"
            >
              Cambiar Imagen
            </button>
          </div>
        </div>

        <!-- Input sobre mi  -->
        <div class="p-2 flex flex-col">
          <div class="flex flex-1 justify-center items-center mt-5 p-2">
            <fwb-textarea
              v-model="aboute_me"
              :rows="4"
              :cols="100"
              label="Sobre mí"
              placeholder="Escríbe una descripción sobre ti para que te conozcan más tus prospectos "
              class="w-full"
            />
          </div>
        </div>
      </div>

      <!-- Formulario datos de Asesor -->
      <div class="w-full sm:w-2/3 mb-4 sm:mb-0">
        <!-- Form -->
        <div class="p-2 flex flex-col-2">
          <div class="flex flex-1 justify-center items-center mt-5 p-2">
            <fwb-input
              v-model="name"
              label="Nombre"
              placeholder="Ingresa tu nombre"
              size="sm"
              class="w-full"
            />
          </div>

          <div class="flex flex-1 justify-center items-center mt-5 p-2">
            <fwb-input
              v-model="last_name"
              label="Apellido paterno"
              placeholder="Ingresa tu apellido paterno"
              size="sm"
              class="w-full"
            />
          </div>          
        </div>

        <div class="p-2 flex flex-col-2">          
          <div class="flex flex-1 justify-center items-center mt-5 p-2">
            <fwb-input
              v-model="last_second_name"
              label="Apellido materno"
              placeholder="Ingresa tu apellido materno"
              size="sm"
              class="w-full"
            />
          </div>
          <div class="flex flex-1 justify-center items-center mt-5 p-2">
            <div
              placeholder="Ingresa tu apellido materno"
              class="w-full"
            >
              <label
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Fecha de nacimiento
              </label>
              <div class="flex relative">
                <VueDatePicker
                  v-model="birthday"
                  class=""
                  :format="format"
                  :locale="es"
                  position="left"
                  :teleport="true" 
                />
              </div>
            </div>
          </div>
        </div>

        <div class="p-2 flex flex-col-2">
          <div class="flex flex-1 justify-center items-center mt-6 p-2">
            <fwb-input
              v-model="email"
              required
              placeholder="Ingresa tu email"
              label="Email"
              class="w-full"
            />
          </div>

          <div class="flex flex-1 justify-center items-center mt-5 p-2">
            <div class="flex-auto mt-3">
              <label
                for="phone"
                class="block mb-3 text-sm font-medium text-gray-900 dark:text-white"
              >
                Teléfono
              </label>

              <InputMask
                id="phone"
                v-model="phone"
                mask="99-99-99-99-99"
                placeholder="ej. 5500119922 "
                class="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 p-2 text-sm"
                fluid
                required
                @keypress="handleInput"
              />
            </div>
          </div>
        </div>
        <div class="p-2 flex flex-col">
          <div class="flex flex-1 justify-end items-end mt-5 p-2">
            <fwb-button
              class="bg-tuin-100 hover:bg-tuin-100"
              size="xl"
              @click="saveData()"
            >
              Guardar
            </fwb-button>
          </div>
        </div>
        <!-- Fin Form -->
      </div>
    </div>
  </div>
</template>

<script setup>
import { FwbInput, FwbTextarea, FwbButton } from "flowbite-vue";
import ToastAlert from "@/commond/ToastAlert.vue";
import InputMask from "primevue/inputmask";
import { ref, computed, onMounted, reactive } from "vue";
import { es } from "date-fns/locale";
import VueDatePicker from "@vuepic/vue-datepicker";
import axios from "axios";

// LLamar a la sesion
import { useStore } from "vuex";
const store = useStore();
let session = computed(() => store.getters.getUserSession);
import { useRouter } from "vue-router";
let router = useRouter();

let name = ref(null);
let birthday = ref();
let email = ref();
let aboute_me = ref();
let phone = ref();
let last_name = ref();
let last_second_name = ref();
let step = ref(1);
let profile_img = ref();

let profile = ref();
let toastAlert = ref(false);
let typeMsg = ref("");
let msg = ref("");

let loader_profile = ref(false);

const dragging = ref(false);
const files = reactive([]);
let img_src = ref(require("@/assets/imgs/avatar_default.png"));

const format = (birthday) => {
  const day = birthday.getDate();
  const month = birthday.getMonth() + 1;
  const year = birthday.getFullYear();

  return `${day}/${month}/${year}`;
};

async function getProfile() {
  try {
    let options = {
      headers: {
        Authorization: `Bearer ${session.value.token_api}`,
      },
    };

    const response = await axios.get(
      `${process.env.VUE_APP_API_BASE_URL}/broker/profile/${session.value.id}`,
      options
    );

    if (response.status == 200) {
      profile.value             = response.data.resp.info;
      name.value                = response.data.resp.info.name;
      last_name.value           = response.data.resp.info.last_name;
      last_second_name.value    = response.data.resp.info.last_second_name;
      email.value               = response.data.resp.info.email;
      phone.value               = response.data.resp.info.phone;
      aboute_me.value           = response.data.resp.info.about_me;
      birthday.value            = response.data.resp.info.birthday;
      profile_img.value         = response.data.resp.info.profile_avatar;
      
      console.log('[***]', response.data.resp.info.profile_avatar)
      if(response.data.resp.info.profile_avatar != null){
        img_src.value             = response.data.resp.info.profile_avatar;
      }
      
      // filteredProperties.value = response.data.resp;
      loader_profile.value = false;
    }
  } catch (error) {
    let response = error.response ;
    if(response.status == 401){
      store.dispatch("clearUserSession"); 
      router.push({ name: 'login' });
    }
    console.error("Error fetching data:", error.response);
  }
}

async function saveData() {
  
  if (session.value.step < 3) {
    step.value = 1;
  } else {
    step.value = session.value.step;
  }

  let validation = await fn_validation();
  console.log(validation['status'])
  
  if(!validation['status']){
    showToast('danger', validation['message'] );
    return;
  }
  loader_profile.value = true;

  let params = {
    name: name.value,
    birthday: birthday.value,
    email: email.value,
    aboute_me: aboute_me.value,
    phone: phone.value.replace(/-/g, ""),
    last_name: last_name.value,
    last_second_name: last_second_name.value,
    step: step.value,
    profile_img: img_src.value,
    source: "broker",
  };

  let options = {
    headers: {
      Authorization: `Bearer ${session.value.token_api}`,
    },
  };

  // try {
    await axios.put(
      `${process.env.VUE_APP_API_BASE_URL}/broker/profile/${session.value.id}/update-general`,
      params,
      options
    ).then(function (response) {
      console.log(response)
      loader_profile.value = false;
      showToast("success", "Datos guardados correctamente");
      console.log(session.value);
      if (session.value.step < 3) {
        router.push({ name: "info_networks" });
        session.value.step = 2;
      }
    }).catch(function(error){
      console.log(error);
      
    });

    // if (response.status == 200) {
    //   loader_profile.value = false;
    //   showToast("success", "Datos guardados correctamente");
    //   console.log(session.value);
    //   if (session.value.step < 3) {
    //     router.push({ name: "info_networks" });
    //     session.value.step = 2;
    //   }
    // }
  // } catch (error) {
  //   loader_profile.value = false;
  //   console.error("Error fetching data:", error);

  //   if(error.response.status == 422){
  //     console.log(error.response.data)
  //     const resp = error.response.data;

  //     for (const field in resp.errors) {
  //       console.log(field);
  //       resp.errors[field].forEach((message) => {
  //           console.log(message)
  //           toastAlert.value = true;
  //             typeMsg.value = "danger";
  //             msg.value = message;

  //             setTimeout(() => {
  //                 closeToast();
  //             }, 3000);
  //       });
  //     }      
  //   }

  // }
}

async function fn_validation(){
  
  if(name.value == '' || name.value == undefined ){
    return {'status': false, 'message': 'El campo nombre es requerido' };
  }
  
  if(last_name.value == '' || last_name.value == undefined){
    return {'status': false, 'message': 'El campo apellido paterno es requerido' };
  }

  if(last_second_name.value == '' || last_second_name.value == undefined ){
    return {'status': false, 'message': 'El campo apellido materno es requerido' };
  }
  
  if(phone.value == '' || phone.value == undefined){
    return {'status': false, 'message': 'El campo teléfono es requerido' };
  }

  if(email.value == '' || email.value == undefined ){
    return {'status': false, 'message': 'El campo email es requerido' };
  }
  
  if(!validateEmail(email.value) ){
    return { 'status': false, 'message': 'Debes ingresar un correo válido'};
  }
  
  
  
  return {'status': true, 'message': 'Todo bien' };
}

function validateEmail(email) {
  const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return re.test(email);
}

async function showToast(type = null, message = null) {
  toastAlert.value = true;
  typeMsg.value = type;
  msg.value = message;

  setTimeout(() => {
    closeToast();
  }, 3000);
}

async function closeToast() {
  toastAlert.value = false;
  typeMsg.value = "";
  msg.value = "";
}

onMounted(async () => {
  try {
    loader_profile.value = true;
    getProfile();
    step.value = session.value.step;
  } catch (error) {
    console.error("Error al intentar traer los datos", error);
  }
});

const fileInput = ref(null);

const handleDrop = (event) => {
  dragging.value = false;
  const droppedFiles = event.dataTransfer.files;
  handleFiles(droppedFiles);
};

const handleFileSelect = (event) => {
  const selectedFiles = event.target.files;
  handleFiles(selectedFiles);
};

const handleFiles = (fileList) => {
  for (let i = 0; i < fileList.length; i++) {
    files.push(fileList[i]);
    convertToBase64(fileList[i]);
  }
};

const triggerFileInput = () => {
  fileInput.value.click(); // Aquí es donde se corrige la referencia
};

const convertToBase64 = (file) => {
  const reader = new FileReader();
  reader.readAsDataURL(file); // Lee el archivo como DataURL (base64)
  reader.onload = () => {
    img_src.value = reader.result; // Asigna el base64 al `src` de la imagen
    // Aquí puedes enviar el `reader.result` al backend si lo deseas
    //sendToBackend(reader.result);
  };
  reader.onerror = (error) => {
    console.error("Error al convertir a base64: ", error);
  };
};


</script>
<style scoped>
/* Si el label tiene clases predeterminadas de Flowbite/Tailwind, puedes sobrescribirlas */
::v-deep .custom-label label {
  @apply text-white;
}

/* También puedes apuntar directamente al selector completo */
::v-deep .custom-label label.block.mb-2.text-sm.font-medium {
  @apply text-white;
}
body {
  background-color: #ffffff !important;
}
</style>
