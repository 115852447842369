<template>
  <div>
    <div
      class="flex flex-col md:flex-row justify-between items-start md:items-center mb-6"
    >
      <div class="mb-4 md:mb-0">
        <span 
          v-show="property.type_movement_id == 1 || property.type_movement_id == 3 "
          class=" bg-tuin_movement-sale text-white py-1 px-2 rounded text-sm"
        >
          VENTA
        </span>

        <span 
          v-show="property.type_movement_id == 2 || property.type_movement_id == 3 "
          class=" bg-tuin_movement-rent text-white py-1 px-2 rounded text-sm"
        >
          Renta
        </span>

        <span 
          v-show="property.type_movement_id == 4 "
          class=" bg-tuin_movement-roomies text-white py-1 px-2 rounded text-sm"
        >
          Roomie
        </span>

        <span 
          class="bg-gray-700 text-white py-1 px-2 rounded text-sm ml-2"
        >
          {{ props.property.property_type }}
        </span>
        <p class="text-gray-400 text-sm mt-2">
          Id del inmueble:
          <span class="text-black">{{ props.property.property_code }}</span> 
          <span class="ml-2"> Publicado: </span>
          <span class="text-black ">{{ props.property.fecha_creation }} </span> 
        </p>
      </div>
    </div>
  </div>
</template>


<script setup>
  
  const props = defineProps({
    property: {
      type: Object,
      required: true,
      default: () => ({ property_name: '' })
    }
  });

  
</script>