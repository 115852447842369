<template>
  <div class="w-full">
    <div
      class="relative overflow-x-auto sm:overflow-hidden shadow-md rounded-lg w-full"
    >
      <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400" border="1" cellpadding="0" cellspacing="0" >
        <thead class="uppercase bg-[#ff4265] text-[#e5e7eb]">
          <tr>
            <th align="center"></th>
            <th align="center">Nombre</th>
            <th align="center">Email</th>
            <th align="center">Teléfono</th>
            
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index)  in props.list" :key="item.id">
            <td align="center">
              <div class="flex justify-center items-center w-full">
                <fwb-checkbox
                  v-model="item.is_checked"
                  @change="addProperty(index)"
                />
              </div>
            </td>
            <td align="center">
              {{ item.full_name }}
            </td>
            <td align="center">
              {{ item.email }}
            </td>
            <td align="center">
              {{ item.phone }}
            </td>            
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script setup>
import { watch, computed } from "vue";

import { useStore } from "vuex";
const store = useStore();
let session = computed(() => store.getters.getUserSession);

import { FwbCheckbox } from "flowbite-vue";

import axios from "axios";

const props = defineProps({
  list: {
    type: Array,
    required: true,
  },
  propertyId: {
    type: Number,
    required: true,
    default: null,
  }
});

watch(
  () => props,
  async (newVal) => {
    console.log("", newVal);
  },
  { immediate: true }
);

async function addProperty(pos) {
  console.log("se debe mandar ", props.list[pos]);

  try {
    let url_link = `${process.env.VUE_APP_API_BASE_URL}/broker/lead/${session.value.id}/add-property`;

    let postParams = {
      property_id: props.propertyId,
      lead_id: props.list[pos].id,
      is_checked: props.list[pos].is_checked
    };

    let options = {
      headers: {
        Authorization: `Bearer ${session.value.token_api}`,
      },
    };

    const response = await axios.post(url_link, postParams, options);

    if (response.status == 401) {
      store.dispatch("clearUserSession");
    }

    if (response.status == 200) {
      console.log(response.message);
    }
  } catch (error) {
    console.error("Error fetching data:", error);
  }

}
</script>
<style scoped>
table {
  border: 2px solid rgb(140 140 140);
}

th,
td {
  border: 1px solid rgb(160 160 160);
  padding: 10px;
}
</style>
