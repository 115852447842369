<template>
  <div
    v-if="props.isLoading"
    class="fixed inset-0 flex items-center justify-center bg-black  z-50"
  >
    <img
      src="@/assets/imgs/tuin_loader.gif"
      alt="cargando..."
      class=" w-[500px]"
    >
  </div>
</template>
  
<script setup>
import { defineProps, watch } from "vue";
const props = defineProps({
  isLoading: {
    type: Boolean,
    required: true,
  },
});

watch(
  () => props,
  (prop) => {
    if (prop.isLoading) {
      // Lógica para cargar datos basados en el nuevo ID
      console.log(`Cargando datos para el ID: ${prop.isLoading}`);
    }
  }
);


</script>
  