import { createApp } from 'vue'
import { CkeditorPlugin } from '@ckeditor/ckeditor5-vue';

import App from './App.vue'
import router from './router'
import store from './store'
import '../node_modules/flowbite-vue/dist/index.css'
/* or in your `app.css` file */
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

import PrimeVue from 'primevue/config';
import './assets/app.css';
import './assets/css/custom.css';
import VueApexCharts from "vue3-apexcharts";
import { definePreset } from '@primevue/themes';

import Aura from '@primevue/themes/aura';
//import Nora from '@primevue/themes/nora';
//const API_KEY = 'AIzaSyDh_2CwOWAcS258EwjQsctRq9ypVZfHAZw';
const customPreset = definePreset(Aura, {
    semantic:{
        primary:{
            50: "{indigo.50}"
        }
    },
    formField: {
        paddingX: '0.75rem',
        paddingY: '0.5rem',
        borderRadius: '{border.radius.md}',
        focusRing: {
            width: '0',
            style: 'none',
            color: 'transparent',
            offset: '0',
            shadow: 'none'
        },
        transitionDuration: '{transition.duration}'
    },
    editor:{
        toolbar: {
            background: "#f00"
        }
    }
})

const app = createApp(App);

app.use(store);
app.use(router);
app.use(VueApexCharts);
app.use(PrimeVue, {
    theme: {   
        presets: customPreset,     
        options: {
            cssLayer: {
                prefix: 'p',
                name: 'primevue', 
                order: 'tailwind-base, tailwind-utilities',
                darkModeSelector: 'system',
                cssLayer: false
            }
        }
    }
});

app.component('VueDatePicker', VueDatePicker);
app.use( CkeditorPlugin );
app.mount('#app');


