<template>
  <div class="w-full">
    <ToastAlert :is-active="toastAlert" :type="typeMsg" :message="msg" />
    <div
      v-show="loader"
      class="flex flex-col rounded-md w-full h-full bg-white shadow-xl mt-4 p-4 justify-center items-center"
    >
      <img
        src="@/assets/imgs/tuin_loader.gif"
        alt="cargando..."
        class="w-[500px]"
      />
    </div>

    <!-- Contenido dinámico basado en el ID -->
    <div v-show="!loader" class=" sm:ml-60 w-full sm:w-80">
      <div>
        <fwb-input
          v-model="name"
          label="Nombre"
          placeholder="ej. Pedro"
          size="sm"
          class="w-full"
          required
        />
      </div>

      <div class="mt-4">
        <fwb-input
          v-model="last_name"
          label="Apellido paterno"
          placeholder="ej. Perez"
          size="sm"
          class="w-full"
          required
        />
      </div>

      <div class="mt-4">
        <fwb-input
          v-model="second_last_name"
          label="Apellido materno"
          placeholder="ej. Lopez"
          size="sm"
          class="w-full"
          required
        />
      </div>

      <div class="mt-4">
        <fwb-input
          v-model="email"
          label="Email"
          type="email"
          placeholder="ej. micorreo@mail.com"
          size="sm"
          class="w-full"
          required
        />
      </div>

      <div class="flex-auto mt-4">
        <label
          for="phone"
          class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          Teléfono
        </label>

        <InputMask
          id="phone"
          v-model="phone"
          mask="99-99-99-99-99"
          placeholder="ej. 5500119922 "
          class="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 p-2 text-sm"
          fluid
          required
          @keypress="handleInput"
        />
      </div>
    </div>

    <div class="flex flex-row mt-5">
      <div class="flex w-1/2 justify-start items-start">
        <fwb-button color="alternative" @click="closeForm" > Cancelar </fwb-button>
      </div>
      <div class="flex w-1/2 justify-end items-end">
        <fwb-button class="bg-tuin_base-0" @click="save"> Guardar </fwb-button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, defineEmits } from "vue";
import { FwbButton, FwbInput } from "flowbite-vue";
import InputMask from "primevue/inputmask";
import ToastAlert from "@/commond/ToastAlert.vue";

import axios from "axios";

import { useStore } from "vuex";
const store = useStore();
let session = computed(() => store.getters.getUserSession);

const emit = defineEmits(["triggerUpdateLead"]);

let loader = false;
let toastAlert = ref(false);
let typeMsg = ref("");
let msg = ref("");

let name = ref("");
let last_name = ref("");
let second_last_name = ref("");
let email = ref("");
let phone = ref("");

async function save() {
  let form_post = {
    name: name.value,
    last_name: last_name.value,
    second_last_name: second_last_name.value,
    email: email.value,
    phone: phone.value.replace(/-/g, ""),
  };

  let validation = await validateForm();

  if (validation.status !== "ok") {
    showToast("danger", validation.message);
    return false;
  }
  loader = true;

  try {
    let options = {
      headers: {
        Authorization: `Bearer ${session.value.token_api}`,
      },
    };

    let url_link = `${process.env.VUE_APP_API_BASE_URL}/broker/lead/${session.value.id}/add`;

    const response = await axios.post(url_link, form_post, options);

    if (response.status == 401) {
      loader = false;
      store.dispatch("clearUserSession");
    }

    if (response.status == 200) {
      loader = false;
      showToast("success", "Datos guardados correctamente");
      closeForm()
    }
  } catch (error) {
    loader = false;
    if (error.response && error.response.status == 422) {
      const resp = error.response.data;
      if (resp.errors) {
        for (const field in resp.errors) {
          console.log(field);
          resp.errors[field].forEach((message) => {
            showToast("danger", message);
            setTimeout(() => {
              closeToast();
            }, 3000);
          });
        }
      }
    }

    console.error("Error fetching data:", error);
  }
}

function closeForm(){
    emit("triggerUpdateLead");
}

async function validateForm() {
  if (name.value == "" || name.value == null) {
    return { status: "fail", message: "El campo nombre es requerido" };
  }
  if (last_name.value == "" || last_name.value == null) {
    return {
      status: "fail",
      message: "El campo apellido paterno es requerido",
    };
  }
  if (second_last_name.value == "" || second_last_name.value == null) {
    return {
      status: "fail",
      message: "El campo apellido materno es requerido",
    };
  }
  if (email.value == "" || email.value == null) {
    return { status: "fail", message: "El campo email es requerido" };
  }

  if (!validateEmail(email.value)) {
    return { status: "fail", message: "Debes ingresar un correo válido" };
  }

  if (phone.value == "" || phone.value == null) {
    return { status: "fail", message: "El campo teléfono es requerido" };
  }
  return { status: "ok", message: "" };
}

function validateEmail(email) {
  const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return re.test(email);
}

async function showToast(type, message) {
  toastAlert.value = true;
  typeMsg.value = type;
  msg.value = message;

  setTimeout(() => {
    closeToast();
  }, 3000);
}

async function closeToast() {
  toastAlert.value = false;
  typeMsg.value = "";
  msg.value = "";
}
</script>
