import { createRouter, createWebHistory } from 'vue-router'
import { useStore } from 'vuex'; // Importa useStore

import DashboardView              from '../views/auth/DashboardView.vue'
// import ProfileView              from '@/views/ProfileView.vue'
// import PropertiesView           from '@/views/PropertiesView.vue'
// import CustomersView            from '@/views/CustomersView.vue'
import LoginView                  from '@/views/LoginView.vue'
import RestoreAccountView         from '@/views/RestoreAccountView.vue'
import InfoProfileView            from '@/views/auth/InfoProfileView.vue'
import InfoCompanyView            from '@/views/auth/InfoCompanyView.vue'
import ActiveAccountView         from '@/views/ActiveAccountView.vue'
import CreateAccountView from '@/views/CreateAccountView.vue';
import InfoNetworksView from '@/views/auth/InfoNetworksView.vue';
import PropertiesView from '@/views/auth/PropertiesView.vue';

import ChangePasswordView from '@/views/auth/ChangePasswordView.vue';
import PropertyFormView from '@/views/auth/properties/PropertyFormView.vue';
import PropertiesListView from '@/views/auth/properties/PropertiesListView.vue';
import LeadsListView from '@/views/auth/leads/LeadsListView.vue';
import RealStateExchangeView from '@/views/auth/exchange/RealStateExchangeView.vue';
import ExchangeListComponent from '@/components/exchange/ExchangeListComponent.vue';
import PropertyDetailComponent from '@/components/exchange/PropertyDetailComponent.vue';
import UpdatePasswordView from '@/views/UpdatePasswordView.vue';

const routes = [
  { path: '/', redirect: '/login' },

  {
    path: '/login',
    name: 'login',
    component: LoginView
  },
  {
    path:'/update-profile-password/:token_temp?',
    name: 'update_profile_password',
    component: UpdatePasswordView
  },
  {
    path: '/create-account',
    name: 'create_account',
    component: CreateAccountView
  },
  {
    path: '/active_account/:token_temp',
    name: 'active_account',
    component: ActiveAccountView
  },  
  {
    path: '/forgot-password',
    name: 'forgot_password',
    component: RestoreAccountView
  },

  {
    path: '/restore_account/:token_temp',
    name: 'restore_account',
    component: RestoreAccountView
  },
  {
    path: '/info-profile',
    name: 'info_profile',
    component: InfoProfileView,
    meta: { requiresAuth: true }
  },
  {
    path: '/info-company',
    name: 'info_company',
    component: InfoCompanyView,
    meta: { requiresAuth: true }
  },
  {
    path: '/info-networks',
    name: 'info_networks',
    component: InfoNetworksView,
    meta: { requiresAuth: true }
  },
  {
    path: '/change-password',
    name: 'change_password',
    component: ChangePasswordView,
    meta: { requiresAuth: true }
  },
  
  {
    path: '/dashboard',
    name: 'dashboard',
    component: DashboardView,
    meta: { requiresAuth: true }
  },
  {
    path: '/Propiedades',
    name: 'properties',
    component: PropertiesView,
    meta: { requiresAuth: true },
    children:[
      {
        path: '',
        name: 'propertyList',// properties/propertyList
        component: PropertiesListView,
        meta: { requiresAuth: true },
      },
      {
        path: 'form',
        name: 'propertyForm',
        component: PropertyFormView,
        meta: { requiresAuth: true },
      },
      {
        path: 'form/:id',
        name: 'propertyFormEdit',
        component: PropertyFormView,
        meta: { requiresAuth: true },
      }
    ]
  },

  {
    path: '/bolsa-inmobiliaria',
    name: 'real_state_exchange',
    component: RealStateExchangeView,
    meta: { requiresAuth: true },
    children:[
      {
        path: '',
        name: 'exchange_list',// properties/propertyList
        component: ExchangeListComponent,
        meta: { requiresAuth: true },
      },
      {
        path: '/propiedad/:property_id',
        name: 'property_detail',// properties/propertyList
        component: PropertyDetailComponent,
        meta: { requiresAuth: true },
      }
    ]
  },

  {
    path: '/Prospectos',
    name: 'leads',
    component: LeadsListView,
    meta: { requiresAuth: true }    
  },  
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.afterEach((to) => {
  if (to.meta.requiresAuth) {
    document.body.classList.remove('bg-dark_bg-100');
    document.body.classList.add('bg-black');
  } else {    
    document.body.classList.remove('bg-black');
    document.body.classList.add('bg-dark_bg-100');    
    document.body.classList.add('bg-dark_bg-100');    
  }
});

router.beforeEach((to, from, next) => {
  const store = useStore(); // Obtén la tienda Vuex
  const isAuthenticated = store.state.userSession; 

  if (to.meta.requiresAuth && !isAuthenticated) {
    next('/login');
  } else if (to.path === '/login' && isAuthenticated) {
    console.log(isAuthenticated)
    //next('/dashboard'); // Redirige al dashboard si está autenticado y trata de acceder a login
    if (isAuthenticated['step'] == 0) {
      next('info-company');
      // next('info-profile');      
    }else if (isAuthenticated['step'] == 1) {
      //router.push({ name: "dashboard" });
      next('info-profile');      
      // next('info-company');
    }else if (isAuthenticated['step'] == 2) {
      //router.push({ name: "dashboard" });
      next('info-networks');      
    }else if (isAuthenticated['step'] == 3) {
      //router.push({ name: "dashboard" });      
      window.location.href = "/Propiedades";
    }
  } else {
    next();
  }

});
export default router
