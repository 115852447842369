<template>
  <div class="container mx-auto h-screen mt-10">
    <ToastAlert
      :is-active="toastAlert"
      :type="typeMsg"
      :message="msg"
    />
    <!-- LOADER  -->
    <div
      v-if="loader_company"
      class="fixed inset-0 flex items-center justify-center bg-black z-50"
    >
      <img
        src="@/assets/imgs/tuin_loader.gif"
        alt="cargando..."
        class="w-[500px]"
      >
    </div>
    <!-- LOADER  -->

    <div class="flex">
      <div class="text-center sm:text-left">
        Para continuar, por favor complete los siguientes campos con su
        información
      </div>
    </div>

    <div class="flex flex-col sm:flex-row mt-4 sm:mt-10">
      <!-- Logo empresa -->
      <div class="w-full sm:w-2/6 mb-4 sm:mb-0">
        <div v-if="!imagePreview">
          <!-- Dropzone visible solo si no hay imagen -->
          <div
            :class="[
              'border-4 border-dashed p-6 rounded-md cursor-pointer',
              dragging
                ? 'border-blue-500 bg-blue-100'
                : 'border-gray-300 bg-gray-50',
            ]"
            class="flex flex-col items-center justify-center space-y-4 p-4 m-4"
            @dragover.prevent
            @dragenter.prevent="dragging = true"
            @dragleave="dragging = false"
            @drop.prevent="handleDrop"
            @click="triggerFileInput"
          >
            <div class="flex justify-center items-center m-auto">
              <img
                id="logo_company"
                :src="img_src"
                alt="Logo de copañía"
              >
            </div>
            <p
              v-if="!is_new"
              class="text-gray-500 justify-center items-center"
            >
              Arrastra y suelta archivos aquí tu logo o haz clic para
              seleccionarlo
            </p>
            <p
              v-if="is_new"
              class="text-gray-500 justify-center items-center"
            >
              Haz clic sobre la imagen si deseas cambiar el logo
            </p>
            <!-- <ul v-else class="list-disc">
          <li v-for="(file, index) in files" :key="index">
            {{ file.name }}
          </li>
        </ul> -->

            <input
              ref="fileInput"
              type="file"
              class="hidden"
              accept="image/*"
              @change="handleFileSelect"
            >
            <button
              class="bg-blue-500 text-white px-4 py-2 rounded hidden"
              @click="triggerFileInput"
            >
              Seleccionar Archivos
            </button>
          </div>
        </div>
        <!-- Preview de la imagen si ya fue cargada -->
        <!-- <div v-if="imagePreview">
          <div class="flex flex-col items-center space-y-4">
            <img
              :src="imagePreview"
              alt="Preview de la imagen"
              class="w-64 h-64 object-cover rounded-md"
            />
            <button
              class="bg-red-500 text-white px-4 py-2 rounded"
              @click="clearImage"
            >
              Cambiar Imagen
            </button>
          </div>
        </div> -->
      </div>
      <!-- Fin Logo empresa -->
      <div class="w-full sm:w-4/6">
        <!-- Contenido del segundo div -->
        <!-- <FormDetailComponent @retriveLogo="retriveLogo" /> -->
        <!-- Inicio form  -->
        <div>
          <div class="flex">
            <div class="w-full sm:w-1/2 p-2">
              <fwb-input
                v-model="inputs.name.value"
                label="Nombre"
                placeholder="ej. Tuin "
                size="sm"
                class="w-full"
              />
            </div>

            <div class="w-full sm:w-1/2 p-2">
              <fwb-input
                v-model="inputs.email.value"
                label="Correo"
                placeholder="ej. hola@tuin.ai"
                size="sm"
                class="w-full"
              />
            </div>
          </div>

          <div class="flex">
            <div class="w-1/2 p-2">
              <div class="flex-auto">
                <label
                  for="phone"
                  class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >Teléfono</label>

                <InputMask
                  id="phone"
                  v-model="inputs.phone.value"
                  mask="99-9999-9999"
                  placeholder="99-9999-9999"
                  class="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 p-2 text-sm"
                  fluid
                />
              </div>
            </div>

            <div class="w-1/2 p-2">
              <fwb-input
                v-model="inputs.website.value"
                label="Sitio web"
                placeholder="ej. https://tuin.ai"
                size="sm"
                class="w-full"
              />
            </div>
          </div>

          <div class="flex">
            <div class="w-1/2 p-2">
              <div class="flex-auto">
                <label
                  for="phone"
                  class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Código postal
                </label>

                <InputMask
                  id="phone"
                  v-model="inputs.zipcode.value"
                  mask="99999"
                  placeholder="99999"
                  class="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 p-2 text-sm"
                  fluid
                  @keypress="handleInput"
                />
              </div>
            </div>

            <div class="w-1/2 p-2">
              <fwb-select
                v-model="inputs.state.value"
                :options="states"
                placeholder="Selecciona un estado"
                label="Estado"
              />
            </div>
          </div>

          <div class="flex">
            <div class="w-1/2 p-2">
              <fwb-input
                v-model="inputs.city.value"
                label="Ciudad"
                placeholder="ej. CDMX"
                size="sm"
                class="w-full"
              />
            </div>

            <div class="w-1/2 p-2">
              <fwb-select
                v-model="inputs.municipality.value"
                :options="municipalities"
                placeholder="Selecciona una delegación"
                label="Delegación / municipio"
              />
            </div>
          </div>

          <div class="flex">
            <div class="w-1/2 p-2">
              <fwb-select
                v-model="inputs.neighborhood.value"
                :options="colonies"
                placeholder="Selecciona una colonía"
                label="Colonía"
              />
            </div>

            <div class="w-1/2 p-2">
              <fwb-input
                v-model="inputs.street.value"
                label="Calle"
                placeholder="ej. Av Coyoacán 1435"
                size="sm"
                class="w-full"
              />
            </div>
          </div>

          <div class="flex">
            <div class="w-1/2">
              <div class="flex">
                <div class="w-1/2 p-2">
                  <fwb-input
                    v-model="inputs.ext_number.value"
                    label="Número exterior"
                    placeholder="ej. 10"
                    size="sm"
                    class="w-full"
                  />
                </div>
                <div class="w-1/2 p-2">
                  <fwb-input
                    v-model="inputs.int_number.value"
                    label="Número interior"
                    placeholder="ej. 1"
                    size="sm"
                    class="w-full"
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="flex justify-end items-end">
            <fwb-button
              class="bg-tuin-100 hover:bg-tuin-100"
              size="xl"
              @click="SaveCompany()"
            >
              Guardar
            </fwb-button>
          </div>
        </div>
        <!-- Fin form  -->
      </div>
    </div>
  </div>
</template>
<script setup>
import ToastAlert from "@/commond/ToastAlert.vue";
import { ref, reactive, watch, computed, onBeforeMount } from "vue";
import { useStore } from "vuex";
import { FwbInput, FwbButton, FwbSelect } from "flowbite-vue";
import InputMask from "primevue/inputmask";
import axios from 'axios';
// import LogoCompanyComponent from "./company/LogoCompanyComponent.vue";
//import FormDetailComponent from "./company/FormDetailComponent.vue";
const store = useStore();
let session = computed(() => store.getters.getUserSession);
//let imageSrc = computed(() => store.getters.imgSrc);
let img_src = ref(require('@/assets/imgs/default_company.png'));

import { useRouter } from "vue-router";
let router = useRouter();
let toastAlert  = ref(false);
let typeMsg     = ref("");
let msg         = ref("");

let inputs = {
    agent_id:     ref(""),
    name:         ref(""),    
    email:        ref(""),
    phone:        ref(""),
    website:      ref(""),
    zipcode:      ref(""),
    state:        ref(""),
    city:         ref(""),
    municipality: ref(""),
    neighborhood: ref(""), // Colonia
    street:       ref(""),
    ext_number:   ref(""),
    int_number:   ref(""),
    img_src:      ref("")
};

let states = ref([]);
let colonies = ref([]);
let municipalities = ref([]);

let loader_company = ref(false);

const dragging = ref(false);
const files = reactive([]);
//require('@/assets/imgs/default_company.png')
//const img_src = ref('');

const is_new = ref(false);

const fileInput = ref(null);

const handleDrop = (event) => {
  dragging.value = false;
  const droppedFiles = event.dataTransfer.files;
  handleFiles(droppedFiles);
};

const handleFileSelect = (event) => {
  const selectedFiles = event.target.files;
  handleFiles(selectedFiles);
};

const handleFiles = (fileList) => {
  for (let i = 0; i < fileList.length; i++) {
    files.push(fileList[i]);
    convertToBase64(fileList[i]);
  }
};

const triggerFileInput = () => {
  fileInput.value.click(); // Aquí es donde se corrige la referencia
};

const convertToBase64 = (file) => {
  const reader = new FileReader();
  reader.readAsDataURL(file); // Lee el archivo como DataURL (base64)
  reader.onload = () => {
    img_src.value = reader.result; // Asigna el base64 al `src` de la imagen
    is_new.value = true;
    //store.dispatch("setImageSrc", reader.result);
    // Aquí puedes enviar el `reader.result` al backend si lo deseas
    //sendToBackend(reader.result);
  };
  reader.onerror = (error) => {
    console.error("Error al convertir a base64: ", error);
  };
};

// function retriveLogo(logo_api) {
//   console.log('[LOGO_WATCH]', logo_api)
//   img_src.value = logo_api
//   setTimeout(()=>{
//     loader_company.value = false;
//   }, 800)
// }
async function SaveCompany(){
    //console.log('enviar guardar ', imageSrc.value)
    let telephone = inputs.phone.value;
    let numeroEntero = telephone.replace(/-/g, '');

    //colony:             inputs.colony.value,

    let params = {
      agent_id:           session.value.id,
      name:               inputs.name.value,
      email:              inputs.email.value,
      phone:              numeroEntero,
      website:            inputs.website.value,
      zipcode:            inputs.zipcode.value,
      state:              inputs.state.value,
      city:               inputs.city.value,
      municipality:       inputs.municipality.value,
      neighborhood:       inputs.neighborhood.value,//Colonia
      street:             inputs.street.value,
      ext_number:         inputs.ext_number.value,
      int_number:         inputs.int_number.value,
      step:               2,
      img_src:            img_src.value
    };
    
    // img_src:            imageSrc.value
    //console.log('ImagenSrc', imageSrc.value)    
    let is_valid = await ValidateForm();

    let link_post = `${process.env.VUE_APP_API_BASE_URL}/broker/profile/${session.value.id}/actualizar_company`;
    
    if(is_valid.status !== 'error' ){
      let options = {
        headers: {
          "Content-Type": "application/json",
          'Authorization': `Bearer ${session.value.token_api}`
        }
      }
      
      const response = await axios.post(link_post, params, options);

      if (response.status == 200) {
        showToast('success', 'Datos guardados correctamente')        
        if(session.value.step < 3 ){
          session.value.step=2;
          //router.push({ name: "info_networks" });
          router.push({ name: "info_profile" });
        }
      }

    }else{
      toastAlert.value  = true;
      typeMsg.value     = "danger";
      msg.value         = is_valid.message;
      setTimeout(()=>{
        closeToast();
      }, 2000);
    } 

}

async function ValidateForm(){
  // validar campos obligatorios
  // console.log('Validaciones ::',inputs.name.value)
  if(inputs.name.value == ''){
    return { 'status': 'error', 'message': 'Debes agregar tu nombre' } 
  }

  if(inputs.email.value == ''){
    return { 'status': 'error', 'message': 'Debes agregar tu correo' } 
  }

  if(inputs.phone.value == ''){
    return { 'status': 'error', 'message': 'Debes agregar tu  teléfono' } 
  }

  if(inputs.zipcode.value == ''){
    return { 'status': 'error', 'message': 'Debes agregar tu  código postal' } 
  }

  if(inputs.state.value == ''){
    return { 'status': 'error', 'message': 'Debes agregar tu estado' } 
  }

  if(inputs.neighborhood.value == ''){
    return { 'status': 'error', 'message': 'Debes agregar tu clonia' } 
  }

  if(inputs.city.value == ''){
    return { 'status': 'error', 'message': 'Debes agregar tu ciudad' } 
  }

  if(inputs.municipality.value == ''){
    return { 'status': 'error', 'message': 'Debes agregar tu municipio' } 
  }

  if(inputs.street.value == ''){
    return { 'status': 'error', 'message': 'Debes agregar tu calle' } 
  }

  if(inputs.ext_number.value == ''){
    return { 'status': 'error', 'message': 'Debes agregar tu no exterior' } 
  }
  // if(inputs.name.value == '' ){

  // }
  return { 'status': 'success', 'message': 'Datos Correctos' }

}

async function GetState(){
  let url_cnn = `${process.env.VUE_APP_API_BASE_URL}/catalogue/states`;

  var options = {
        headers: {
        'Content-Type': 'application/json', // Es importante establecer el tipo de contenido para el envío de archivos
        }
  }

  axios.get(url_cnn,  options)
  .then(response => {        
      var resp = response.data.resp;                                
      states.value = resp.map(estado => {
                      return {
                        value: estado.id,         // Usando el id como value
                        name: estado.estado       // Usando el nombre del estado como name
                      };
                    });   
  })
  .catch(error => {
      console.log(error)
      if(error.response.status == 401){
          console.log('Debe cerrar la sesión')
      }
  });
}

async function ConsultZip(zipCode){
    let url_cnn = `${process.env.VUE_APP_API_BASE_URL}/catalogue/cp/${zipCode}`;
    
    var options = {
        headers: {
        'Content-Type': 'application/json', // Es importante establecer el tipo de contenido para el envío de archivos
        'Authorization': `Bearer ${session.value.token_api}` // Reemplaza tuTokenBearer con tu token real
        }
    }
    
    axios.get(url_cnn,  options)
    .then(response => {
      var resp = response.data.data;                                    
      // verifica que el CP retorne datos
      if(resp.length > 0){
        // Sacar el estado 
        inputs.state.value = resp[0]['estado_id']
      
        //asignar municipio
        municipalities.value = [{
                          value: resp[0]['municipio_id'],
                          name: resp[0]['municipio'],
                        }];

        inputs.municipality.value =resp[0]['municipio_id']
        
        if(inputs.city.value == ''){
          inputs.city.value =resp[0]['ciudad']
        }
        
        //asignar colonia
        if(resp.length == 1){ 
          // Solo aplica si nada mas me retorna un resultado
          inputs.neighborhood.value =  resp[0]['colonia']
        }

        colonies.value = resp.map(colony => {
                        return {
                          value: colony.id,         // Usando el id como value
                          name: colony.colonia       // Usando el nombre del estado como name
                        };
                      });                        
      }else{
        
        toastAlert.value = true;
        typeMsg.value    = 'danger'
        msg.value        = "Verifica tu código postal"
        setTimeout(() => {
          closeToast()
        }, 3000);
      }
    })
    .catch(error => {
        console.log(error)
        if(error.response.status == 401){
            console.log('Debe cerrar la sesión')
        }
    });
}

async function handleInput() {
  
  let cleanedZipcode = inputs.zipcode.value.replace(/_/g, '');
  
  if (cleanedZipcode.length == 5) {
    cleanedZipcode = cleanedZipcode.slice(0, 5);
    ConsultZip(inputs.zipcode.value)
    return false
  }
  
  
}

async function getInfoCompany(){
  
  let options = {
    headers: {
      Authorization: `Bearer ${session.value.token_api}`,
    },
  };

  let link_api = `${process.env.VUE_APP_API_BASE_URL}/broker/profile/${session.value.id}/company`;
  const response = await axios.get(
      link_api,
      options
    );

  try {
    if (response.status == 401) {
      store.dispatch("clearUserSession");
      loader_company.value = false;
    }

    if (response.status == 200) {
      let info = response.data.resp.info;
      
      inputs.agent_id.value       =  session.value.id;
      inputs.name.value          =  info.name;
      inputs.email.value       =  info.email;
      inputs.phone.value          =  info.phone;
      inputs.website.value        =  info.website;
      inputs.city.value     =  info.city;
      inputs.street.value    =  info.street;
      inputs.ext_number.value     =  info.ext_number;
      inputs.int_number.value     =  info.int_number;
      
      inputs.neighborhood.value         = info.neighborhood;

      inputs.zipcode.value  =  info.zipcode;

      if(info.zipcode){
        await ConsultZip(info.zipcode)
      }
      inputs.state.value          =  info.state;
      inputs.municipality.value   =  info.municipality;
      inputs.neighborhood.value         =  info.neighborhood;
      console.log('[logo]', info.logo)
      if(info.logo){
        img_src.value = info.logo;
      }
      // filteredProperties.value = response.data.resp;
      loader_company.value = false;
    }
  } catch (error) {
    loader_company.value = false;
    console.error("Error fetching data:", error);
  }
}

async function showToast( type = null, message = null){
  toastAlert.value  = true;
  typeMsg.value     = type;
  msg.value         = message;

  setTimeout(() => {
    closeToast();
  }, 3000);
}

async function closeToast(){
  toastAlert.value = false;
  typeMsg.value    = ''
  msg.value        = '' 
}

onBeforeMount(async() => {
  loader_company.value = true;
  await GetState();
  await getInfoCompany();
});

watch(
  img_src, // Observa directamente el valor de la referencia
  (newVal) => {
    console.log(newVal.logo);
    if (newVal.logo) {
      // Lógica para cargar datos basados en el nuevo valor
      img_src.value = newVal.logo;
    }
  },
  { immediate: true } // Ejecuta el watch inmediatamente al montar el componente
);
</script>