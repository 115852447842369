import { createStore } from 'vuex'
//import { useRouter }  from 'vue-router';
//let router              = useRouter();

export default createStore({
  state: {
    step: 0,
    userSession: null,
    imgSrc: localStorage.getItem('imageSrc') || require('@/assets/imgs/default_company.png'),

  },
  getters: {
    getUserSession: (state) => state.userSession,
    imgSrc: (state) => state.imgSrc,
  },
  mutations: {
    setUserSession(state, session) {
      state.userSession = session;
    },
    clearUserSession(state) {
      state.userSession = null;      
    },    
    SET_IMAGE_SRC(state, imgSrc) {
      state.imgSrc = imgSrc;
      localStorage.setItem('imageSrc', imgSrc); // Guarda también en localStorage
    },
    UPDATE_STEP(state, newStep) {
      if (state.userSession) {
        state.userSession.step = newStep;
  
        // Actualiza el sessionStorage para mantener la consistencia
        let session_var = JSON.stringify(state.userSession);
        sessionStorage.setItem('userSession', session_var);
        console.log('cambio de session step')
      }
    }
  },
  actions: {
    saveUserSession({ commit }, session) {
      commit('setUserSession', session);
      localStorage.setItem('userSession', JSON.stringify(session));
    },
    loadUserSession({ commit }) {
      const session = localStorage.getItem('userSession');
      
      if (session) {
        commit('setUserSession', JSON.parse(session));         
      }
    },
    clearUserSession({ commit }) {
      commit('clearUserSession');
      localStorage.removeItem('userSession');
      //router.push({ name: 'login' }); 
    },
    clearDataUserSession({ commit }) {
      commit('clearUserSession');
      localStorage.removeItem('userSession');
    },
    setImageSrc({ commit }, imgSrc) {
      commit('SET_IMAGE_SRC', imgSrc);
    },
    updateStep({ commit }, newStep) {      
      commit('UPDATE_STEP', newStep);
    },
  },
  modules: {
  }
})
