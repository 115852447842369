<template>
  <div>
    <!-- Mapa -->
    <div class="flex flex-wrap mt-10">
      <div class="flex flex-1">
        <fwb-heading tag="h2">
          Propiedad
        </fwb-heading>
      </div>
      <div class="flex flex-1 justify-end items-end">
        <fwb-breadcrumb>
          <fwb-breadcrumb-item href="/properties">
            Lista de propiedades
          </fwb-breadcrumb-item>

          <fwb-breadcrumb-item> Agregar nuevo inmueble </fwb-breadcrumb-item>
        </fwb-breadcrumb>
      </div>
    </div>
    <!-- Mapa -->

    <!-- inicio de contenido -->
    <div
      class="w-full bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 mt-5 sm:mt-10"      
    >
      <fwb-tabs
        v-model="activeTab"
        class="p-5"
      >
        <fwb-tab
          name="first"
          title="Datos generales"
        >
          <p class="text-sm font-normal leading-6 text-black "> 
            Por favor, completa todos los campos obligatorios marcados con un asterisco (*). 
            La información precisa y detallada ayudará a que tu inmueble destaque en el mercado. 
          </p>

          <FormComponent 
            :property-id="id" 
            @trigger-update-property="updateProperty" 
          />        
        </fwb-tab>

        <fwb-tab 
          name="gallery" 
          title="Galería" 
          :disabled="!active_tab"
        >
          <FormGalleryComponent 
            :property-id="id" 
          />
        </fwb-tab>

        <fwb-tab 
          name="characters" 
          title="Características" 
          :disabled="!active_tab"
        >
          <FormCharacteristicsComponent 
            :property-id="id" 
            section="characteristics"
          /> 
        </fwb-tab>

        <fwb-tab 
          name="amenities" 
          title="Amenidades" 
          :disabled="!active_tab"
        >
          <FormCharacteristicsComponent 
            :property-id="id" 
            section="amenities"
          /> 
        </fwb-tab>

        <fwb-tab 
          name="friendly" 
          title="Friendly" 
          :disabled="!active_tab"
        >
          <FormCharacteristicsComponent 
            :property-id="id" 
            section="friendly"
          /> 
        </fwb-tab>

        <fwb-tab 
          name="requeriments" 
          title="Requisitos" 
          :disabled="!active_tab"
        >
          <FormCharacteristicsComponent 
            :property-id="id" 
            section="requeriments"
          /> 
        </fwb-tab>
      </fwb-tabs>
    </div>
  </div>
</template>
<script setup>
import FormCharacteristicsComponent from "@/components/properties/FormCharacteristicsComponent.vue";
import FormComponent from "@/components/properties/FormComponent.vue";
import FormGalleryComponent from "@/components/properties/FormGalleryComponent.vue";
 
import {
  FwbBreadcrumb,
  FwbBreadcrumbItem,
  FwbHeading,
  FwbTab,
  FwbTabs,
} from "flowbite-vue";
import { ref, onMounted, watch } from "vue";
import { useRoute } from "vue-router";

// Acceder al parámetro 'id' de la URL directamente
const route = useRoute();
let id = route.params.id;
let active_tab = ref(false);

const activeTab = ref("first");

onMounted(async () => {
  console.log('[ID PROPIEDAD]',id)
  if (id != undefined) {
    active_tab.value = true;
  }
});

async function updateProperty(property_id){
  console.log('actualiza estado de tabs', property_id)
  active_tab.value = true;
}

watch(
  () => route.params.id,
  (newId) => {
    console.log('[-----]',newId)
      id =  newId
  }
);

</script>