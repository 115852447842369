<template>
  <div>
    <div
      v-if="loader"
      class="fixed inset-0 flex items-center justify-center bg-black z-50"
    >
      <img
        src="@/assets/imgs/tuin_loader.gif"
        alt="cargando..."
        class="w-[500px]"
      />
    </div>
    <!-- Mapa del sitio  -->
    <div class="flex flex-wrap mt-10">
      <div class="flex flex-1">
        <fwb-heading tag="h2"> Prospectos </fwb-heading>
      </div>
      <div class="flex flex-1 justify-end items-end">
        <fwb-breadcrumb>
          <fwb-breadcrumb-item href="/Prospectos">
            Lista de prospectos
          </fwb-breadcrumb-item>
        </fwb-breadcrumb>
      </div>
    </div>
    <!-- Mapa del sitio  -->

    <div class="flex flex-col justify-end items-end mt-4">
      <button
        class="font-bold rounded-lg text-sm w-48 h-10 bg-tuin_base-0 text-white justify-center items-center"
        @click="addProspect('new')"
      >
        <img
          src="@/assets/imgs/icons/prospect.svg"
          alt="Nuevo prospecto"
          class="object-cover float-left pl-2"
          width="30"
        />
        <span class="float-left pl-2">Nuevo prospecto</span>
      </button>
    </div>

    <div
      class="flex flex-col rounded-md w-full h-full bg-white shadow-xl mt-4 p-4"
    >
      <div
        class="relative overflow-hidden shadow-md rounded-lg t w-full h-screen"
      >
        <table class="table-fixed w-full text-lef">
          <thead class="uppercase bg-[#ff4265] text-[#e5e7eb]">
            <tr>
              <!--[-->
              <!-- <td
                class="py-0 border text-center font-bold p-4"
                contenteditable="true"
              >
                &nbsp;
              </td> -->
              <td
                class="py-0 border text-center font-bold p-4"
                contenteditable="true"
              >
                Nombre
              </td>
              <td
                class="py-0 border text-center font-bold p-4"
                contenteditable="true"
              >
                Apellidos
              </td>
              <td
                contenteditable="true"
                class="py-0 border text-center font-bold p-4"
              >
                Email
              </td>
              <td
                contenteditable="true"
                class="py-0 border text-center font-bold p-4"
              >
                Teléfono
              </td>

              <td
                contenteditable="true"
                class="py-0 border text-center font-bold p-4"
              >
                Contactar
              </td>
              <td
                contenteditable="true"
                class="py-0 border text-center font-bold p-4"
              >
                &nbsp;
              </td>
              <!--]-->
            </tr>
          </thead>
          <tbody
            class="bg-white text-[#646464]"
            style="background-color: #ffffff; color: #646464"
          >
            <!--[-->
            <tr
              v-for="(row, index) in list"
              v-show="list.length > 0"
              :key="index"
              class="py-0"
            >
              <!--[-->
              <!-- <td 
                class="py-0 border p-4 text-center" 
                contenteditable="true"
              >
                <fwb-avatar>
                  <template #placeholder>
                    <svg
                      class="w-12 h-12"
                      fill="none"
                      stroke-width="1.5"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </template>
                </fwb-avatar>
              </td> -->
              <td class="py-0 border p-4">
                {{ row.name }}
              </td>
              <td class="py-0 border p-4">
                {{ row.last_name }} {{ row.second_last_name }}
              </td>
              <td class="py-0 border p-4">
                {{ row.email }}
              </td>
              <td class="py-0 border p-4">
                {{ row.phone }}
              </td>

              <td class="py-0 border p-4" align="center">
                <a :href="`tel:+52${row.phone}`">
                  <button class="bg-tuin_base-0 p-2 text-white rounded-xl">
                    <img
                      src="@/assets/imgs/icons/phone.svg"
                      alt="Contactar vía teléfono"
                      width="25"
                    >
                  </button>
                </a>

                <a
                  :href="`https://wa.me/52${row.phone}?text=Hola%20${row.name }`"
                  target="_blank"
                >
                  <button
                    class="rounded-sm bg-tuin_base-0 p-2 m-2 text-white rounded-xl"
                  >
                    <img
                      :tel="`tel:+52${row.phone}`"
                      target="_blank"
                      src="@/assets/imgs/icons/wp.svg"
                      alt="Contactar vía Whatsapp"
                      width="25"
                    >
                  </button>
                </a>
              </td>

              <td class="py-0 border p-4 text-center">
                <!-- opciones -->
                <fwb-dropdown placement="left" text="Left">
                  <template #trigger>
                    <img
                      src="@/assets/imgs/icons/points.png"
                      alt="tuin bolsa inmobiliaria"
                      width="30"
                      class="cursor-pointer"
                    />
                  </template>
                  <fwb-list-group>
                    <fwb-list-group-item
                      class="cursor-pointer hover:bg-tuin_base-0 hover:text-white"
                      @click="addProspect('edit', row.id)"
                    >
                      Editar
                    </fwb-list-group-item>

                    <fwb-list-group-item
                      class="cursor-pointer hover:bg-tuin_base-0 hover:text-white"
                      @click="AssignProperty(row.id)"
                    >
                      Asignar propiedad
                    </fwb-list-group-item>
                    <fwb-list-group-item
                      class="cursor-pointer hover:bg-tuin_base-0 hover:text-white"
                      @click="ModalConfirmDelete(row.id)"
                    >
                      Eliminar
                    </fwb-list-group-item>
                  </fwb-list-group>
                </fwb-dropdown>
                <!-- opciones -->
              </td>
              <!--]-->
            </tr>
            <!--]-->

            <tr v-show="list.length == 0">
              <td colspan="5" class="text-center">
                <div class="m-6 flex justify-center items-center">
                  Aún no has dado de alta prospectos.
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <LeadFormView
      v-if="mostrarModal"
      :lead-id="prospectId"
      :is-show-modal="mostrarModal"
      @cerrar="CloseFormModal"
    />

    <ModalPropertiesComponent
      v-if="mostrarModalProperties"
      :prospect-id="prospectId"
      @close="ClosePropertiesModal"
    />
  </div>
</template>

<script setup>
import {
  FwbBreadcrumb,
  FwbBreadcrumbItem,
  FwbHeading,
  FwbDropdown,
  FwbListGroup,
  FwbListGroupItem,
} from "flowbite-vue";
//import LoaderTuin from "@/commond/LoaderTuin.vue";
import LeadFormView from "@/components/leads/LeadFormView.vue";
import ModalPropertiesComponent from "@/components/leads/ModalPropertiesComponent.vue";

//import InfoGralComponent from "@/components/leads/InfoGralComponent.vue";
import axios from "axios";
import { ref, onMounted, computed } from "vue";

import { useStore } from "vuex";
const store = useStore();
let session = computed(() => store.getters.getUserSession);

let loader = ref(false);
let list = ref([]);
let formModalOpen = ref(false);
let prospectId = ref("");

let mostrarModal = ref(false);
let mostrarModalProperties = ref(false);

function addProspect(action, prospect_id) {
  if (action == "edit") {
    prospectId.value = prospect_id;
  }

  mostrarModal.value = true;
}

function ModalConfirmDelete() {
  console.log("debe confirmar la eliminación");
}

function AssignProperty(leadId) {
  mostrarModalProperties.value = true;
  prospectId.value = leadId;
}

function CloseFormModal(isUpdate = false) {
  formModalOpen.value = 0;
  prospectId.value = "";
  mostrarModal.value = false;
  if (isUpdate) {
    loader.value = true;
    getLeads();
  }
}

async function getLeads() {
  try {
    let options = {
      headers: {
        Authorization: `Bearer ${session.value.token_api}`,
      },
    };

    const response = await axios.get(
      `${process.env.VUE_APP_API_BASE_URL}/broker/leads/${session.value.id}`,
      options
    );

    if (response.status == 401) {
      store.dispatch("clearUserSession");
    }

    if (response.status == 200) {
      list.value = response.data.resp;
    }
  } catch (error) {
    console.error("Error fetching data:", error);
  }

  loader.value = false;
}

function ClosePropertiesModal() {
  mostrarModalProperties.value = false;
}

onMounted(async () => {
  loader.value = true;
  await getLeads();
});
</script>

<style scoped>
.icon-svg {
  font-size: #fff;
}
</style>
